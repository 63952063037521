var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Flex from 'antd/lib/flex';
import styled from 'styled-components';
export var FullFlex = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), function (_a) {
    var $fullHeight = _a.$fullHeight;
    return $fullHeight && "height: 100%;";
}, function (_a) {
    var $fullWidth = _a.$fullWidth;
    return $fullWidth && "width: 100%;";
});
var templateObject_1;
