import React from 'react';
import { SERVICE_STAGING } from 'renderer/constants';
import { getDocumentReference, toCamelCase } from 'renderer/utils';
import { onSnapshot } from '@firebase/firestore';
import { FallbackCalendar, FullFlex } from 'renderer/components';
var ServiceStatus;
(function (ServiceStatus) {
    ServiceStatus["ACTIVE"] = "active";
    ServiceStatus["MAINTENANCE"] = "maintenance";
    ServiceStatus["ALERT"] = "alert";
})(ServiceStatus || (ServiceStatus = {}));
// TODO: 기존 maintanence 삭제 및 버젼 및
// TODO: 나중엔 빌드넘버까지 해서 최신버젼 바로바로 업데이트 가능하도록 추가
export var StatusRenderer = function (props) {
    var _a = React.useState({
        state: ServiceStatus.ACTIVE
    }), status = _a[0], satStatus = _a[1];
    React.useEffect(function () {
        var docRef = getDocumentReference('serviceStatus', toCamelCase(['server', SERVICE_STAGING]));
        var unsubscribe = onSnapshot(docRef, {
            next: function (snapshot) {
                if (snapshot.data()) {
                    satStatus(snapshot.data());
                }
            }
        });
        return function () { return unsubscribe(); };
    }, []);
    switch (status.state) {
        case ServiceStatus.MAINTENANCE:
            return (React.createElement(FullFlex, { align: "center", justify: "center", vertical: true },
                React.createElement("h1", null, status.title || '팅커뷰는 현재 점검중입니다.'),
                React.createElement("div", null, status.message || '')));
        case ServiceStatus.ALERT:
            return React.createElement(FallbackCalendar, { status: status, isPC: props.isPC });
        case ServiceStatus.ACTIVE:
        default:
            return props.children;
    }
};
