var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Card from 'antd/lib/card';
import React from 'react';
import { commonCssTypography, Icon, TextComponent } from 'renderer/components';
import { typographyByName } from 'renderer/components/Typography';
import Row from 'antd/lib/row';
import { useLocalStorage } from 'renderer/utils';
import styled, { css } from 'styled-components';
import { COLORS, MediaQuery } from 'renderer/styles';
import { Col } from 'antd/lib';
export var CollapsibleCardComponent = function (props) {
    var localStorageKey = props.localStorageKey, _a = props.callapsible, callapsible = _a === void 0 ? true : _a, rest = __rest(props, ["localStorageKey", "callapsible"]);
    var _b = !!callapsible && localStorageKey
        ? useLocalStorage(localStorageKey, true, { store: true, manager: true })
        : React.useState(true), collapsed = _b[0], setCollapsed = _b[1];
    var title = (React.createElement(CardTitle, { title: props.title, onChangeCollapsed: function () {
            setCollapsed(!collapsed);
        }, callapsible: callapsible, collapsed: collapsed }));
    return React.createElement(StyledCallapsibleCard, __assign({ size: "small" }, rest, { "$collapsed": collapsed, title: title }));
};
var CardTitle = function (props) {
    var title = props.title, collapsed = props.collapsed, onChangeCollapsed = props.onChangeCollapsed, callapsible = props.callapsible;
    return (React.createElement(Row, { justify: "space-between", align: "middle", wrap: false },
        React.createElement(Col, { flex: 1 }, typeof title === 'string' ? React.createElement(TextComponent, { type: "body1" }, title) : title),
        callapsible && (React.createElement(Col, null,
            React.createElement(IconButton, { name: collapsed ? 'up-chevron' : 'down-chevron', onClick: onChangeCollapsed })))));
};
var IconButton = styled(function (props) { return React.createElement(Icon, __assign({}, props)); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var StyledCallapsibleCard = styled(Card)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  &.ant-card {\n    // border: 1px solid ", ";\n    ", "\n  }\n\n  .ant-card-body {\n    border-top: 1px solid ", ";\n    background-color: ", ";\n    display: ", ";\n  }\n"], ["\n  &.ant-card {\n    // border: 1px solid ", ";\n    ", "\n  }\n\n  .ant-card-body {\n    border-top: 1px solid ", ";\n    background-color: ", ";\n    display: ", ";\n  }\n"])), COLORS.gray4, MediaQuery.tabletWithMobile(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      height: 100%;\n    "], ["\n      height: 100%;\n    "]))), COLORS.gray4, COLORS.gray6, function (props) { return (props.$collapsed ? 'none' : 'block'); });
export var CardWithSubmit = function (props) {
    return React.createElement(StyledCardWithSubmit, __assign({}, props));
};
export var StyledCardWithSubmit = styled(Card)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  &.ant-card {\n    border: 1px solid ", ";\n    ", "\n  }\n\n  & .ant-card-head {\n    ", "\n  }\n\n  & .ant-card-head-title {\n    ", "\n    ", "\n  }\n\n  & .ant-card-body {\n    padding: 36px 26px 26px;\n    ", "\n  }\n  & .ant-card-actions {\n    & > li {\n      padding: 16px 24px;\n      margin: 0px;\n    }\n  }\n"], ["\n  &.ant-card {\n    border: 1px solid ", ";\n    ", "\n  }\n\n  & .ant-card-head {\n    ", "\n  }\n\n  & .ant-card-head-title {\n    ", "\n    ", "\n  }\n\n  & .ant-card-body {\n    padding: 36px 26px 26px;\n    ", "\n  }\n  & .ant-card-actions {\n    & > li {\n      padding: 16px 24px;\n      margin: 0px;\n    }\n  }\n"])), COLORS.gray6, MediaQuery.tabletWithMobile(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      height: 100%;\n    "], ["\n      height: 100%;\n    "]))), MediaQuery.tabletWithMobile(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      display: none;\n    "], ["\n      display: none;\n    "]))), commonCssTypography, css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      ", "\n    "], ["\n      ", "\n    "])), typographyByName('headline2')), MediaQuery.tabletWithMobile(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      padding: 16px;\n      flex:1;\n    "], ["\n      padding: 16px;\n      flex:1;\n    "]))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
