var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useForm } from 'antd/lib/form/Form';
import Row from 'antd/lib/row';
import React from 'react';
import { COLORS } from 'renderer/styles';
import { omitEmptyValues, every, isEmpty, Lodash, set, isNilOrEmptyArray, isDate, times, isNumber } from 'renderer/utils';
import styled, { css } from 'styled-components';
import { Icon, AntPrimaryGhostButton, ResetButton, TextComponent, AntdForm, BigTitleModal, KeywordSearcher, typographyByName, StyledFormItem, StyledDivider, InputByType, NumberFormatInput, DatePickerAutoaccept, useContentHeight, KRWFomatWithoutWon } from 'renderer/components';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'renderer/constants';
import Input from 'antd/lib/input/Input';
import clsx from 'clsx';
import Col from 'antd/lib/col';
import Checkbox from 'antd/lib/checkbox';
import Select from 'antd/lib/select';
import Radio from 'antd/lib/radio';
var Option = Select.Option, OptGroup = Select.OptGroup;
export var FilterWithSearcher = function (props) {
    var _a;
    var _b = React.useState(false), visible = _b[0], setVisible = _b[1];
    var form = useForm()[0];
    var handleSubmit = function (value) {
        Object.keys(value).map(function (key) {
            var _a, _b;
            var data = value[key];
            if ((_a = data === null || data === void 0 ? void 0 : data.start) === null || _a === void 0 ? void 0 : _a.toDate) {
                value[key].start = data.start.toDate();
            }
            if ((_b = data === null || data === void 0 ? void 0 : data.end) === null || _b === void 0 ? void 0 : _b.toDate) {
                value[key].end = data.end.toDate();
            }
        });
        props.onChange(__assign({}, value));
        setVisible(false);
    };
    var handleReset = function (stopPropagation) {
        var _a;
        var initialValue = {};
        (_a = props.filter) === null || _a === void 0 ? void 0 : _a.map(function (filter) {
            if (filter.type === 'checkbox') {
                initialValue[filter.key] = undefined;
            }
            else {
                initialValue[filter.key] = undefined;
            }
        });
        form.setFieldsValue(initialValue);
        if (!stopPropagation) {
            props.onChange(__assign({ keyword: props === null || props === void 0 ? void 0 : props.value.keyword }, initialValue));
        }
    };
    var handleDelete = function (deletedKey) {
        var newData = {};
        var newFormData = {};
        Object.keys(props.value).map(function (key) {
            if (deletedKey === key) {
                return;
            }
            else {
                var targetValue = props.value[key];
                if (targetValue || (targetValue === null || targetValue === void 0 ? void 0 : targetValue.start) || (targetValue === null || targetValue === void 0 ? void 0 : targetValue.end)) {
                    if (isDate(targetValue)) {
                        newData[key] = dayjs(targetValue).toDate();
                        newFormData[key] = dayjs(targetValue);
                    }
                    else if (isDate(targetValue.start) || isDate(targetValue.end)) {
                        newData[key] = {
                            start: targetValue.start ? dayjs(targetValue.start).toDate() : undefined,
                            end: targetValue.end ? dayjs(targetValue.end).toDate() : undefined
                        };
                        newFormData[key] = {
                            start: targetValue.start ? dayjs(targetValue.start) : undefined,
                            end: targetValue.end ? dayjs(targetValue.end) : undefined
                        };
                    }
                    else {
                        newData[key] = targetValue;
                        newFormData[key] = targetValue;
                    }
                }
            }
        });
        form.setFieldsValue(newFormData);
        props.onChange(newData);
    };
    var _c = props.value || {}, keyword = _c.keyword, rest = __rest(_c, ["keyword"]);
    var hasFilter = Lodash.some(Object.keys(props.value).map(function (key) {
        var value = props.value && props.value[key];
        return (value && value.start) || (value && value.end);
    }));
    var _d = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _d[1];
    React.useEffect(function () {
        if (visible) {
            var newValue_1 = {};
            Object.keys(rest).map(function (key) {
                var value = rest[key];
                if (isDate(value === null || value === void 0 ? void 0 : value.start) || isDate(value === null || value === void 0 ? void 0 : value.end)) {
                    newValue_1[key] = {
                        start: (value === null || value === void 0 ? void 0 : value.start) && dayjs(value.start),
                        end: (value === null || value === void 0 ? void 0 : value.end) && dayjs(value.end)
                    };
                }
                else {
                    newValue_1[key] = value;
                }
            });
            form.setFieldsValue(newValue_1);
            forceUpdate();
        }
        else {
            handleReset(true);
        }
    }, [visible]);
    var y = useContentHeight(false, true);
    return (React.createElement("div", null,
        React.createElement(Row, { wrap: false },
            !props.hideKeyword && (React.createElement(KeywordSearcher, { placeholder: props.searcherPlaceholder || '고객명, 연락처 빠른검색', value: ((_a = props.value) === null || _a === void 0 ? void 0 : _a.keyword) || '', onChange: function (keyword) {
                    props.onChange(__assign(__assign({}, props.value), { keyword: keyword }));
                } })),
            React.createElement(AntPrimaryGhostButton, { size: "large", onClick: function () { return setVisible(true); }, icon: React.createElement(Icon, { name: "sliders", style: { marginRight: '5px' } }), children: "\uD544\uD130" }),
            props.extra ? props.extra : React.createElement(Col, null)),
        React.createElement(Row, { style: { marginTop: '10px' }, align: "middle" },
            Object.keys(props.value).map(function (key) {
                var _a;
                var targetFilter = (_a = props.filter) === null || _a === void 0 ? void 0 : _a.find(function (filter) { return filter.key === key; });
                var value = props.value && props.value[key];
                var isShown = ((targetFilter === null || targetFilter === void 0 ? void 0 : targetFilter.type) === 'checkbox' && !isEmpty(value)) ||
                    ((targetFilter === null || targetFilter === void 0 ? void 0 : targetFilter.type) === 'MMDDRange' && !isEmpty(omitEmptyValues(value))) ||
                    ((targetFilter === null || targetFilter === void 0 ? void 0 : targetFilter.type) === 'text' && !!value) ||
                    ((targetFilter === null || targetFilter === void 0 ? void 0 : targetFilter.type) === 'dateWithOperator' && !!value) ||
                    (((value === null || value === void 0 ? void 0 : value.start) !== undefined || (value === null || value === void 0 ? void 0 : value.end) !== undefined) &&
                        (Lodash.isNumberOrDate(value === null || value === void 0 ? void 0 : value.start) || Lodash.isNumberOrDate(value === null || value === void 0 ? void 0 : value.end)));
                return (isShown &&
                    targetFilter && (React.createElement(FilterValueLabel, { onClick: function () { return setVisible(true); }, targetFilter: targetFilter, key: key, keyString: key, label: targetFilter.title, value: value, onDelete: handleDelete })));
            }),
            hasFilter && (React.createElement(ResetButton, { onClick: function () { return handleReset(false); }, icon: React.createElement(Icon, { name: "rotate", color: COLORS.calendarRedActive }) }, "\uCD08\uAE30\uD654"))),
        React.createElement(BigTitleModal, { styles: {
                body: {
                    maxHeight: y,
                    overflow: 'overlay'
                }
            }, centered: true, width: 360, maskClosable: false, cancelText: "\uB2EB\uAE30", okText: "\uC800\uC7A5", onCancel: function () { return setVisible(false); }, onOk: form.submit, title: '필터', open: visible, children: React.createElement(FilterForm, { form: form, onReset: handleReset, onSubmit: handleSubmit, filter: props.filter, value: rest }) })));
};
var FilterForm = function (props) {
    var _a;
    var form = props.form;
    var renderItems = function (input) {
        switch (input.type) {
            case 'checkbox':
                return (React.createElement(StyledCheckboxWithAll, { form: form, dataKey: input.key, itemProps: {
                        name: [input.key]
                    }, label: input.title, items: input.items }));
            case 'numberRange':
                var inputProps = input.inputProps;
                var placeholder = (inputProps === null || inputProps === void 0 ? void 0 : inputProps.placeholder) || '';
                var suffix = (inputProps === null || inputProps === void 0 ? void 0 : inputProps.suffix) || '';
                return (React.createElement(StyledRangeInput, { label: input.title, start: {
                        type: 'number',
                        itemProps: {
                            name: [input.key, 'start'],
                            rules: [{ type: 'number', required: false }]
                        },
                        inputProps: { placeholder: placeholder, suffix: suffix }
                    }, end: {
                        type: 'number',
                        itemProps: {
                            name: [input.key, 'end'],
                            rules: [{ type: 'number', required: false }]
                        },
                        inputProps: { placeholder: placeholder, suffix: suffix }
                    } }));
            case 'dateRange':
                return (React.createElement(StyledRangeInput, { label: input.title, start: {
                        type: 'datepicker',
                        itemProps: {
                            name: [input.key, 'start'],
                            rules: [{ type: 'date', required: false }]
                        },
                        inputProps: { placeholder: '년/월/일' }
                    }, end: {
                        type: 'datepicker',
                        itemProps: {
                            name: [input.key, 'end'],
                            rules: [{ type: 'date', required: false }]
                        },
                        inputProps: { placeholder: '년/월/일' }
                    } }));
            case 'radio':
                return (React.createElement(StyledRadioInFilter, { form: form, label: input.title, itemProps: { name: input.key, label: input.title }, items: input.items }));
            case 'text':
                return React.createElement(StyledInput, { label: input.title, itemProps: { name: input.key, label: input.title }, inputProps: {} });
            case 'dateWithOperator':
                return (React.createElement(StyledDateWithOperator, { form: form, label: input.title, itemProps: { name: input.key, label: input.title }, inputProps: {} }));
            case 'MMDDRange':
                return (React.createElement(StyledMMDDRange, { form: form, label: input.title, itemProps: { name: input.key, label: input.title }, inputProps: {} }));
        }
    };
    return (React.createElement(FormWrapper, null,
        React.createElement(Row, { justify: "end" },
            React.createElement(ResetButton, { onClick: function () { return props.onReset(true); }, icon: React.createElement(Icon, { name: "rotate", color: COLORS.calendarRedActive }) }, "\uCD08\uAE30\uD654")),
        React.createElement(AntdForm, { allowEnterSubmit: true, formProps: {
                form: form,
                layout: 'vertical',
                initialValues: props.value,
                onFinish: props.onSubmit
            }, onSubmit: props.onSubmit, fields: ((_a = props.filter) === null || _a === void 0 ? void 0 : _a.map(function (filter) { return ({ element: renderItems(filter) }); })) || [] })));
};
var FilterValueLabel = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var renderRangeValue = function (value) {
        return value === null || value === undefined || value === ''
            ? ''
            : value.mm && value.dd
                ? "".concat(value.mm, "\uC6D4 ").concat(value.dd, "\uC77C")
                : Lodash.isDate(value) || (value === null || value === void 0 ? void 0 : value.toDate)
                    ? dayjs(value).format(DATE_FORMAT)
                    : isNumber(value)
                        ? KRWFomatWithoutWon({ value: Number(value) })
                        : '';
    };
    var renderDateWithOperator = function (operator, value) {
        if (isNilOrEmptyArray(value) || !operator) {
            return '';
        }
        if (operator === '$between') {
            return "".concat(props.label, ": ").concat(value.start ? dayjs(value.start).format(DATE_FORMAT) : '', " ~ ").concat(value.end ? dayjs(value.end).format(DATE_FORMAT) : '');
        }
        else if (operator === null || operator === void 0 ? void 0 : operator.includes('Day')) {
            var isGTE = operator.includes('$gte');
            return "".concat(props.label, ": ").concat(isGTE ? "\uCD5C\uADFC ".concat(value, "\uC77C \uC774\uB0B4") : "".concat(value, "\uC774\uC0C1 \uACBD\uACFC"));
        }
        else {
            var isLTE = operator.includes('$lte');
            return "".concat(props.label, ": ").concat(isLTE ? '≦' : '≧').concat(dayjs(value).format(DATE_FORMAT));
        }
    };
    return (React.createElement(FilterValueWrapper, { onClick: props.onClick },
        ((_a = props.targetFilter) === null || _a === void 0 ? void 0 : _a.type) === 'checkbox' && !isEmpty(props.value) ? (React.createElement(TextComponent, { ellipsis: true, color: COLORS.white, children: "".concat(props.label, " : ").concat(props.value
                .map(function (value) {
                var _a;
                return (_a = props.targetFilter.items.find(function (item) { return item.value === value; })) === null || _a === void 0 ? void 0 : _a.label;
            })
                .filter(Boolean)
                .join(',')) })) : ((_b = props.targetFilter) === null || _b === void 0 ? void 0 : _b.type) === 'text' ? (React.createElement(TextComponent, { ellipsis: true, color: COLORS.white, children: "".concat(props.label, " : ").concat(props.value) })) : ((_c = props.targetFilter) === null || _c === void 0 ? void 0 : _c.type) === 'dateWithOperator' ? (React.createElement(TextComponent, { ellipsis: true, color: COLORS.white, children: renderDateWithOperator((_d = props.value) === null || _d === void 0 ? void 0 : _d.operator, props.value.value) })) : (React.createElement(TextComponent, { color: COLORS.white, children: "".concat(props.label, ": ").concat(renderRangeValue((_e = props.value) === null || _e === void 0 ? void 0 : _e.start), " ~ ").concat(renderRangeValue((_f = props.value) === null || _f === void 0 ? void 0 : _f.end)) })),
        React.createElement(DeleteFilter, { onClick: function (ev) {
                ev.stopPropagation();
                ev.preventDefault();
                props.onDelete(props.keyString);
            } },
            React.createElement(Icon, { name: "close", color: COLORS.white }))));
};
var FilterValueWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 24px;\n  padding: 2px 10px;\n  border-radius: 15px;\n  background-color: var(--primary-1);\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  margin-right: 5px;\n  cursor: pointer;\n  margin-top: 5px;\n  max-width: 400px;\n"], ["\n  height: 24px;\n  padding: 2px 10px;\n  border-radius: 15px;\n  background-color: var(--primary-1);\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  margin-right: 5px;\n  cursor: pointer;\n  margin-top: 5px;\n  max-width: 400px;\n"])));
var DeleteFilter = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 10px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n"], ["\n  padding: 10px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n"])));
var FormWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 20px;\n"], ["\n  padding: 20px;\n"])));
var RadioInFilter = function (props) {
    var className = props.className, label = props.label, itemProps = props.itemProps, items = props.items;
    var value = props.form.getFieldValue([itemProps.name]);
    var _a = React.useState(), guide = _a[0], setGuide = _a[1];
    React.useEffect(function () {
        var _a;
        var newGuide = (_a = items.find(function (item) { return item.value === value; })) === null || _a === void 0 ? void 0 : _a.guide;
        if (newGuide !== guide) {
            setGuide(newGuide);
        }
    }, [value]);
    return (React.createElement("div", { className: props.className },
        React.createElement(StyledFormItem, __assign({}, itemProps, { className: "label" }),
            React.createElement(Radio.Group, { onChange: function (e) {
                    var _a;
                    var value = e.target.value;
                    var newGuide = (_a = items.find(function (item) { return item.value === value; })) === null || _a === void 0 ? void 0 : _a.guide;
                    if (newGuide !== guide) {
                        setGuide(newGuide);
                    }
                } }, items.map(function (item) {
                return (React.createElement(Radio, { key: item.value, value: item.value }, item.label));
            }))),
        guide && (React.createElement(Row, { align: "middle", style: { marginTop: '-0px' } },
            React.createElement(Icon, { name: "alert-triangle", color: COLORS.info }),
            React.createElement(TextComponent, { type: "caption1", color: COLORS.info, children: guide, marginLeft: 5 })))));
};
var TextInFilter = function (props) {
    var className = props.className, label = props.label, itemProps = props.itemProps, inputProps = props.inputProps, rest = __rest(props, ["className", "label", "itemProps", "inputProps"]);
    return (React.createElement("div", { className: props.className },
        React.createElement(StyledFormItem, { className: "label" }, props.label),
        React.createElement(StyledFormItem, __assign({}, itemProps, { label: "", wrapperCol: { span: 24 } }),
            React.createElement(Input, __assign({}, inputProps)))));
};
var RangeInput = function (props) {
    var _a, _b;
    var start = props.start, end = props.end, className = props.className;
    return (React.createElement("div", { className: className },
        React.createElement(StyledFormItem, { className: "label" }, props.label),
        React.createElement(Row, { gutter: 8, style: { width: '100%' }, align: "middle", wrap: false },
            React.createElement(Col, { flex: 1 },
                React.createElement(StyledFormItem, __assign({ wrapperCol: { span: 24 }, style: { width: '100%' } }, start.itemProps, { className: clsx((_a = start === null || start === void 0 ? void 0 : start.itemProps) === null || _a === void 0 ? void 0 : _a.className) }), InputByType(start))),
            React.createElement(Col, null,
                React.createElement(StyledDivider, { type: "horizontal", style: { width: '20px' }, marginBottom: 8 })),
            React.createElement(Col, { flex: 1 },
                React.createElement(StyledFormItem, __assign({ wrapperCol: { span: 24 } }, end.itemProps, { className: clsx((_b = end === null || end === void 0 ? void 0 : end.itemProps) === null || _b === void 0 ? void 0 : _b.className) }), InputByType(end))))));
};
export var CheckboxWithAll = function (props) {
    var label = props.label, items = props.items, itemProps = props.itemProps, form = props.form, dataKey = props.dataKey, className = props.className;
    var value = form.getFieldValue([dataKey]);
    React.useEffect(function () {
        setCheckAll(every(items.map(function (item) { return value === null || value === void 0 ? void 0 : value.includes(item.value); })));
    }, [value]);
    var _a = React.useState(every(items.map(function (item) { return value === null || value === void 0 ? void 0 : value.includes(item.value); }))), checkAll = _a[0], setCheckAll = _a[1];
    return (React.createElement("div", { className: className },
        React.createElement(StyledFormItem, { className: "label" }, label),
        React.createElement(StyledFormItem, __assign({ wrapperCol: { span: 24 } }, itemProps),
            React.createElement(StyledCheckGroup, { options: items, onChange: function (checkedValue) {
                    setCheckAll(every(items.map(function (item) { return checkedValue === null || checkedValue === void 0 ? void 0 : checkedValue.includes(item.value); })));
                } }))));
};
var StyledCheckGroup = styled(Checkbox.Group)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex-wrap: wrap;\n  & .ant-checkbox-wrapper {\n    margin-inline-start: 0px;\n  }\n"], ["\n  flex-wrap: wrap;\n  & .ant-checkbox-wrapper {\n    margin-inline-start: 0px;\n  }\n"])));
var MMDDRange = function (props) {
    var className = props.className, label = props.label, itemProps = props.itemProps, inputProps = props.inputProps, form = props.form;
    var monthOptions = times(13, function (index) {
        return {
            label: index === 0 ? '선택안함' : "".concat(index, "\uC6D4"),
            value: index
        };
    });
    var dayOptions = times(31, function (index) {
        return {
            label: "".concat(index + 1, "\uC77C"),
            value: index + 1
        };
    });
    var _a = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _a[1];
    var rednerField = function (key) {
        return (React.createElement(Col, { span: 11 },
            React.createElement(Row, { wrap: false, style: { width: '100%' }, gutter: 4 },
                React.createElement(Col, { span: 12 },
                    React.createElement(StyledFormItem, __assign({}, itemProps, { wrapperCol: { span: 24 }, name: ["".concat(itemProps.name), key, 'mm'], label: "" }),
                        React.createElement(Select, { options: monthOptions, onChange: function (value) {
                                if (!value) {
                                    form.setFieldValue(["".concat(itemProps.name), key], {
                                        mm: undefined,
                                        dd: undefined
                                    });
                                    return forceUpdate();
                                }
                                else {
                                    if (!form.getFieldValue(["".concat(itemProps.name), key, 'dd'])) {
                                        form.setFieldValue(["".concat(itemProps.name), key, 'dd'], 1);
                                        return forceUpdate();
                                    }
                                }
                            } }))),
                React.createElement(Col, { span: 12 },
                    React.createElement(StyledFormItem, __assign({}, itemProps, { wrapperCol: { span: 24 }, name: ["".concat(itemProps.name), key, 'dd'], label: "" }),
                        React.createElement(Select, { options: dayOptions, onChange: function (value) {
                                if (!form.getFieldValue(["".concat(itemProps.name), key, 'mm'])) {
                                    form.setFieldValue(["".concat(itemProps.name), key, 'mm'], 1);
                                    return forceUpdate();
                                }
                            } }))))));
    };
    return (React.createElement("div", { className: className },
        React.createElement(StyledFormItem, { className: "label" }, props.label),
        React.createElement(Row, { wrap: true, gutter: 8, style: { width: '100%' }, align: "middle" },
            rednerField('start'),
            React.createElement(Col, { span: 2 },
                React.createElement(StyledDivider, { type: "horizontal", style: { width: '20px' }, marginBottom: 8 })),
            rednerField('end'))));
};
var DateWithOperator = function (props) {
    var className = props.className, label = props.label, itemProps = props.itemProps, inputProps = props.inputProps, form = props.form, rest = __rest(props, ["className", "label", "itemProps", "inputProps", "form"]);
    var operatorNames = [itemProps.name, 'operator'];
    var valueNames = [itemProps.name, 'value'];
    var _a = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _a[1];
    var operator = form.getFieldValue(operatorNames);
    return (React.createElement("div", { className: className },
        React.createElement(StyledFormItem, __assign({}, itemProps, { className: "label", name: operatorNames, labelCol: { span: 24 }, wrapperCol: { span: 24 } }),
            React.createElement(Select, __assign({ placeholder: "\uAC80\uC0C9 \uC870\uAC74 \uC120\uD0DD" }, inputProps, { onChange: function (value) {
                    var newValue = set({}, valueNames, null);
                    form.setFieldsValue(newValue);
                    forceUpdate();
                } }),
                React.createElement(Option, { value: "" }, "\uD544\uD130\uC5C6\uC74C"),
                React.createElement(OptGroup, { label: "\uB2EC\uB825\uC5D0\uC11C \uB0A0\uC9DC \uC120\uD0DD" },
                    React.createElement(Option, { value: "$lte" }, "\uC774\uC804"),
                    React.createElement(Option, { value: "$gte" }, "\uC774\uD6C4"),
                    React.createElement(Option, { value: "$between" }, "\uBC94\uC704")),
                React.createElement(OptGroup, { label: "\uD574\uB2F9\uC77C\uB85C\uBD80\uD130 \uAE30\uAC04" },
                    React.createElement(Option, { value: "$gteDay" }, "\uCD5C\uADFC ~\uC77C \uC774\uB0B4"),
                    React.createElement(Option, { value: "$lteDay" }, "~\uC77C \uC774\uC0C1 \uACBD\uACFC")))),
        operator ? (React.createElement(StyledFormItem, { name: valueNames, className: "operatorWith" }, operator.includes('Day') ? (React.createElement(NumberFormatInput, null)) : operator === '$between' ? (React.createElement(StyledRangeInput, { start: {
                type: 'datepicker',
                itemProps: {
                    name: __spreadArray(__spreadArray([], valueNames, true), ['start'], false),
                    rules: [{ type: 'date', required: false }]
                },
                inputProps: { placeholder: '년/월/일' }
            }, end: {
                type: 'datepicker',
                itemProps: {
                    name: __spreadArray(__spreadArray([], valueNames, true), ['end'], false),
                    rules: [{ type: 'date', required: false }]
                },
                inputProps: { placeholder: '년/월/일' }
            } })) : (React.createElement(DatePickerAutoaccept, __assign({ inputReadOnly: true }, inputProps, { showNow: false }))))) : (React.createElement("div", null))));
};
var StyledInput = styled(TextInFilter)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  & .label {\n    margin-bottom: -5px !important;\n    min-height: 20px;\n    & .ant-form-item-control-input-content {\n      ", "\n    }\n  }\n  margin-bottom: 5px !important;\n"], ["\n  & .label {\n    margin-bottom: -5px !important;\n    min-height: 20px;\n    & .ant-form-item-control-input-content {\n      ", "\n    }\n  }\n  margin-bottom: 5px !important;\n"])), css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        ", "\n      "], ["\n        ", "\n      "])), typographyByName('subtitle1')));
var StyledRangeInput = styled(RangeInput)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  & .label {\n    margin-bottom: -5px !important;\n    min-height: 20px;\n    & .ant-form-item-control-input-content {\n      ", "\n    }\n  }\n  margin-bottom: 5px !important;\n"], ["\n  & .label {\n    margin-bottom: -5px !important;\n    min-height: 20px;\n    & .ant-form-item-control-input-content {\n      ", "\n    }\n  }\n  margin-bottom: 5px !important;\n"])), css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n        ", "\n      "], ["\n        ", "\n      "])), typographyByName('subtitle1')));
var StyledCheckboxWithAll = styled(CheckboxWithAll)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  & .label {\n    margin-bottom: -5px !important;\n    min-height: 20px;\n    & .ant-form-item-control-input-content {\n      ", "\n    }\n  }\n  margin-bottom: 5px !important;\n"], ["\n  & .label {\n    margin-bottom: -5px !important;\n    min-height: 20px;\n    & .ant-form-item-control-input-content {\n      ", "\n    }\n  }\n  margin-bottom: 5px !important;\n"])), css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n        ", "\n      "], ["\n        ", "\n      "])), typographyByName('subtitle1')));
var StyledRadioInFilter = styled(RadioInFilter)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  & .label {\n    margin-bottom: -5px !important;\n    min-height: 20px;\n    & .ant-form-item-control-input-content {\n      ", "\n    }\n  }\n  margin-bottom: 5px !important;\n"], ["\n  & .label {\n    margin-bottom: -5px !important;\n    min-height: 20px;\n    & .ant-form-item-control-input-content {\n      ", "\n    }\n  }\n  margin-bottom: 5px !important;\n"])), css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n        ", "\n      "], ["\n        ", "\n      "])), typographyByName('subtitle1')));
var StyledDateWithOperator = styled(DateWithOperator)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  & .operatorWith {\n    & .label {\n      display: none;\n    }\n  }\n\n  & label.ant-form-item-no-colon {\n    width: 100%;\n    ", ";\n  }\n"], ["\n  & .operatorWith {\n    & .label {\n      display: none;\n    }\n  }\n\n  & label.ant-form-item-no-colon {\n    width: 100%;\n    ", ";\n  }\n"])), css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n      ", "\n    "], ["\n      ", "\n    "])), typographyByName('subtitle1')));
var StyledMMDDRange = styled(MMDDRange)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  & .label {\n    margin-bottom: -5px !important;\n    min-height: 20px;\n    & .ant-form-item-control-input-content {\n      ", "\n    }\n  }\n  margin-bottom: 5px !important;\n"], ["\n  & .label {\n    margin-bottom: -5px !important;\n    min-height: 20px;\n    & .ant-form-item-control-input-content {\n      ", "\n    }\n  }\n  margin-bottom: 5px !important;\n"])), css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n        ", "\n      "], ["\n        ", "\n      "])), typographyByName('subtitle1')));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
