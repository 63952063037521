var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { formmattedMessage } from 'renderer/components';
import clsx from 'clsx';
import { Lodash } from 'renderer/utils';
import styled, { css } from 'styled-components';
import Tooltip from 'antd/lib/tooltip';
var PrevTextComponent = function (props) {
    var children = props.children, style = props.style, className = props.className;
    var styles = __assign({}, style);
    return (React.createElement("div", __assign({}, props, { onClick: props.onClick, style: props.color ? __assign(__assign({}, styles), { color: props.color }) : styles, className: clsx([className]) }), props.id ? formmattedMessage({ id: props.id }) : children || ''));
};
export var commonCssTypography = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: Noto Sans KR;\n  font-size: 14px;\n  font-weight: 500;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  color: var(--black);\n  letter-spacing: -0.2px;\n  word-break: break-all;\n"], ["\n  font-family: Noto Sans KR;\n  font-size: 14px;\n  font-weight: 500;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  color: var(--black);\n  letter-spacing: -0.2px;\n  word-break: break-all;\n"])));
export var TextComponent = styled(PrevTextComponent)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  ", "\n  text-align: ", ";\n  ", "\n  ", "\n  ", "\n  ", "\n \n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  ", "\n  text-align: ", ";\n  ", "\n  ", "\n  ", "\n  ", "\n \n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), commonCssTypography, function (props) { return (props.textAlign ? props.textAlign : 'left'); }, function (props) {
    return props.ellipsis && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      // text-overflow: ellipsis;\n      text-overflow: '';\n    "], ["\n      // text-overflow: ellipsis;\n      text-overflow: '';\n    "])));
}, function (props) {
    return props.ellipsis && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      white-space: nowrap;\n    "], ["\n      white-space: nowrap;\n    "])));
}, function (props) {
    return props.ellipsis && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      overflow: hidden;\n    "], ["\n      overflow: hidden;\n    "])));
}, function (props) {
    return props.type && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      ", ";\n    "], ["\n      ", ";\n    "])), typographyByName(props.type));
}, function (props) {
    return Lodash.isNumber(props.marginTop) && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      margin-top: ", "px;\n    "], ["\n      margin-top: ", "px;\n    "])), props.marginTop || 0);
}, function (props) {
    return Lodash.isNumber(props.marginBottom) && css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      margin-bottom: ", "px;\n    "], ["\n      margin-bottom: ", "px;\n    "])), props.marginBottom || 0);
}, function (props) {
    return Lodash.isNumber(props.marginLeft) && css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      margin-left: ", "px;\n    "], ["\n      margin-left: ", "px;\n    "])), props.marginLeft || 0);
}, function (props) {
    return Lodash.isNumber(props.marginRight) && css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      margin-right: ", "px;\n    "], ["\n      margin-right: ", "px;\n    "])), props.marginRight || 0);
}, function (props) {
    return Lodash.isNumber(props.margin) && css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n      margin: ", "px;\n    "], ["\n      margin: ", "px;\n    "])), props.margin || 0);
});
export var typographyByName = function (type) {
    switch (type) {
        case 'headline1':
            return "font-size: 24px;\n    font-weight: 700;\n    line-height: 34px;\n    letter-spacing: -0.6px;\n    ";
        case 'headline2':
            return "font-size: 20px;\n    font-weight: 700;\n    line-height: 28px;\n    letter-spacing: -0.6px;";
        case 'headline3':
            return "font-size: 16px;\n    font-weight: 700;\n    line-height: 22px;\n    letter-spacing: -0.4px;";
        case 'subtitle1':
            return "font-size: 16px;\n      letter-spacing: -0.6px;\n      line-height: 22px;\n    font-weight: 500";
        case 'subtitle2':
            return "font-size: 14px;\n      letter-spacing: -0.6px;\n    line-height: 20px;\n    font-weight: 500;";
        case 'subtitle3':
            return "font-size: 12px;\n      letter-spacing: -0.6px;\n      line-height: 18px;\n    font-weight: 500;";
        case 'body1':
            return "font-size: 16px;\n      letter-spacing: -0.4px;\n      line-height: 24px;\n    font-weight: 400;";
        case 'body2':
            return "font-size: 14px;\n      letter-spacing: -0.4px;\n      line-height: 22px;\n    font-weight: 400;";
        case 'caption1':
            return "font-size: 12px;\n      letter-spacing: -0.6px;\n      line-height: 20px;\n    font-weight: 400;";
        case 'caption2':
            return "font-size: 10px;\n      letter-spacing: -0.6px;\n      line-height: 18px;\n    font-weight: 400;";
    }
};
export var ClickableText = styled(TextComponent)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
export var UnderlineClickableText = styled(ClickableText)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  text-decoration: underline;\n  cursor: pointer;\n"], ["\n  text-decoration: underline;\n  cursor: pointer;\n"])));
export var TextWithTooltip = function (props) {
    var tooltipTitle = props.tooltipTitle, rest = __rest(props, ["tooltipTitle"]);
    return (React.createElement(Tooltip, { title: tooltipTitle, trigger: ['hover', 'click'] },
        React.createElement(TextComponent, __assign({}, rest, { children: "".concat(props.children, " \u24D8") }))));
};
export var PreLineText = styled(TextComponent)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  white-space: pre-line;\n  max-height: ", "px;\n  overflow-y: overlay;\n  width: 100%;\n"], ["\n  white-space: pre-line;\n  max-height: ", "px;\n  overflow-y: overlay;\n  width: 100%;\n"])), function (props) { return props.$height || 100; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
