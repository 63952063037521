var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import Button from 'antd/lib/button';
import { Col } from 'antd/lib/grid';
import Row from 'antd/lib/row';
import Tabs from 'antd/lib/tabs';
import TabPane from 'rc-tabs/lib/TabPanelList/TabPane';
import { Controller, useForm } from 'react-hook-form';
import { IMAGES } from 'renderer/assets';
import { ManagerQuery, NaverLambdaQuery, ProductQuery, StoreQuery } from 'renderer/queries';
import { times, get, set, useWindowSize, orderBy, flatten, LambdaClient, pick, omitEmptyValues, isEmpty, addOrRemove, createUUID, omit, openWindow, getDayNamessBetweenDates, some } from 'renderer/utils';
import styled from 'styled-components';
import { ScrollBar, EntireBigTitleModal, HookFormInputs, TextComponent, useEntireLoader, CustomDropdown, FloatingButtonGroup, EntireLoader, NaverBlockModal, Icon, AlertComponent, DrawerComponent, ButtonWithPlusCircle, BigTitleModal, syncNaverCalendar, FullFlex, ErrorDisplayer } from 'renderer/components';
import Card from 'antd/lib/card';
import Alert from 'antd/lib/alert';
import notification from 'antd/lib/notification';
import Switch from 'antd/lib/switch';
import Checkbox from 'antd/lib/checkbox';
import { COLORS } from 'renderer/styles';
import Modal from 'antd/lib/modal';
import Empty from 'antd/lib/empty';
import { Layout } from 'common-ui';
import * as dayjs from 'dayjs';
import Calendar from 'antd/lib/calendar';
import { isEqual } from 'lodash';
export var SmartPlcaceSetter = function (props) {
    var _a;
    var _b = React.useState(), visible = _b[0], setVisible = _b[1];
    var _c = useWindowSize(), height = _c.height, isPC = _c.isPC;
    var width = isPC ? '32px' : '20px';
    var _d = React.useState(), targetDate = _d[0], setTargetDate = _d[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(CustomDropdown, { items: [
                {
                    name: 'pull',
                    label: '예약 가능시간 동기화',
                    disabled: !((_a = props.store) === null || _a === void 0 ? void 0 : _a.isAvailableNaverSmartplace),
                    onClick: function () {
                        setTargetDate(props.currentDate);
                    }
                },
                {
                    name: 'settings',
                    label: '네이버예약 설정',
                    onClick: function () {
                        setVisible(true);
                    }
                },
                {
                    name: 'details',
                    label: '연동방법 자세히보기',
                    onClick: function () {
                        openWindow('https://help.thinkofyou.kr/255ed0aa-8ed2-4ca9-b0a5-aed7d29b4154', '연동방법 자세히보기');
                    }
                }
            ] },
            React.createElement("img", { src: IMAGES.schedule_icon_naver, style: { width: width, height: width } })),
        React.createElement(EntireBigTitleModal, { open: visible, title: "\uB124\uC774\uBC84 \uC608\uC57D \uC124\uC815", children: React.createElement(ScrollBar, { style: { height: "".concat(height - 55, "px") } },
                React.createElement(Settings, { store: props.store })), footer: null, onCancel: function () {
                setVisible(false);
            } }),
        React.createElement(NaverBlockModal, { targetDate: targetDate, onClose: function () { return setTargetDate(null); } })));
};
var Settings = function (props) {
    var _a;
    var loader = useEntireLoader();
    var handleUpdate = function (store, hideNotification) { return __awaiter(void 0, void 0, void 0, function () {
        var result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    loader.show();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, 6, 7]);
                    return [4 /*yield*/, LambdaClient.getInstance().put("/store/preference", {
                            preference: store.preference,
                            id: props.store.id
                        })];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, StoreQuery.refreshMyStoreInCache()];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, bizItemsWithOptions.refetch()];
                case 4:
                    _a.sent();
                    if (!hideNotification) {
                        notification.success({ message: '네이버연동 설정이 변경되었습니다.' });
                    }
                    return [2 /*return*/, result];
                case 5:
                    error_1 = _a.sent();
                    notification.error({ message: error_1.message || '네이버 로그인에 실패했습니다.' });
                    return [3 /*break*/, 7];
                case 6:
                    loader.hide();
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var bizItemsWithOptions = NaverLambdaQuery.useNaverItemsWithOptions({ storeId: (_a = props.store) === null || _a === void 0 ? void 0 : _a.id }, {
        enabled: !!props.store.isAvailableNaverSmartplace,
        retry: false
    });
    var _b = React.useState('bizItems'), activeKey = _b[0], setActiveKey = _b[1];
    var data = bizItemsWithOptions.data, isError = bizItemsWithOptions.isError, isLoading = bizItemsWithOptions.isLoading;
    return React.useMemo(function () {
        var _a, _b, _c;
        return (React.createElement(TabContent, null, ((_a = props.store) === null || _a === void 0 ? void 0 : _a.isAvailableNaverSmartplace) ? (isError ? (React.createElement(FullFlex, { "$fullHeight": true, "$fullWidth": true, justify: "center", align: "center" },
            React.createElement(ErrorDisplayer, { isUpdated: false, title: "\uB124\uC774\uBC84 \uC5F0\uB3D9 \uC815\uBCF4 \uBD88\uB7EC\uC624\uAE30 \uC2E4\uD328", messages: [
                    '설정에 문제가 있어 네이버 연동 정보를 불러오는데 실패했습니다.',
                    '새로고침을 해도 문제가 있다면 관리자에게 문의하여 연동을 초기화해주세요.'
                ], onClick: function () {
                    bizItemsWithOptions.refetch();
                } }))) : isLoading ? (React.createElement(EntireLoader, { open: true })) : (React.createElement(Tabs, { defaultActiveKey: "bizItems", activeKey: activeKey, onChange: function (key) {
                setActiveKey(key);
            } },
            React.createElement(TabPane, { tab: React.createElement(Tab, { label: "\uC9C1\uC6D0 \uC5F0\uACB0" }), key: "bizItems" },
                React.createElement(ProductOrManagerLink, { store: props.store, key: "bizItems", type: "bizItems", activeKey: activeKey, data: data, items: (_b = data === null || data === void 0 ? void 0 : data.bizItems) === null || _b === void 0 ? void 0 : _b.map(function (item) {
                        return {
                            id: item.bizItemId,
                            label: item.name,
                            isImp: item.isImp
                        };
                    }) })),
            React.createElement(TabPane, { tab: React.createElement(Tab, { label: "\uC2DC\uC220 \uC5F0\uACB0" }), key: "options" },
                React.createElement(ProductOrManagerLink, { key: "options", store: props.store, type: "options", activeKey: activeKey, data: data, items: flatten((_c = data === null || data === void 0 ? void 0 : data.categories) === null || _c === void 0 ? void 0 : _c.map(function (category) {
                        var categoryName = category.name;
                        return (category.options || []).map(function (option) {
                            return {
                                id: option.optionId,
                                label: "".concat(categoryName, "-").concat(option.name)
                            };
                        });
                    })) })),
            React.createElement(TabPane, { tab: React.createElement(Tab, { label: "\uB124\uC774\uBC84 \uC0C1\uC810 \uC815\uBCF4" }), key: "info" },
                React.createElement(NaverInformation, { store: props.store, handleUpdate: handleUpdate })),
            React.createElement(TabPane, { tab: React.createElement(Tab, { label: "\uD305\uCEE4\uBDF0 \uC5F0\uB3D9 \uC124\uC815" }), key: "block" },
                React.createElement(BlockAvailableTime, { store: props.store, handleUpdate: handleUpdate }))))) : (React.createElement(AccountSetting, { store: props.store }))));
    }, [data, isError, isLoading, activeKey, props.store]);
};
var AccountSetting = function (props) {
    var form = useForm();
    var loader = useEntireLoader();
    var handleLogin = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var modalInstance;
        return __generator(this, function (_a) {
            modalInstance = modal.confirm({
                centered: true,
                okText: '계속',
                cancelText: '취소',
                title: '네이번 연동전 확인사항',
                onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var error_2;
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                loader.show();
                                _b.label = 1;
                            case 1:
                                _b.trys.push([1, 4, 5, 6]);
                                return [4 /*yield*/, LambdaClient.getInstance().post("/naver", __assign(__assign({}, values), { storeId: props.store.id }))];
                            case 2:
                                _b.sent();
                                return [4 /*yield*/, StoreQuery.refreshMyStoreInCache()];
                            case 3:
                                _b.sent();
                                notification.success({ message: '네이버 정보 연동 성공' });
                                return [3 /*break*/, 6];
                            case 4:
                                error_2 = _b.sent();
                                if ((_a = error_2 === null || error_2 === void 0 ? void 0 : error_2.message) === null || _a === void 0 ? void 0 : _a.includes('already exist')) {
                                    notification.error({
                                        message: '이미 타프로그램을 통해 네이버 연동이 되어있습니다. 이전에 사용하시던 프로그램이 있다면, 해당 업체 고객센터를 통해 네이버예약 연동을 해제한 뒤 진행해주세요.'
                                    });
                                }
                                else {
                                    notification.error({ message: error_2.message || '네이버 연동에 실패했습니다.' });
                                }
                                return [3 /*break*/, 6];
                            case 5:
                                loader.hide();
                                modalInstance.destroy();
                                return [7 /*endfinally*/];
                            case 6: return [2 /*return*/];
                        }
                    });
                }); },
                content: (React.createElement("div", null,
                    React.createElement(AlertComponent, { message: '네이버 스마트플레이스 관리자페이지에서 쿠폰을 발행하셨나요?', description: React.createElement("div", null,
                            React.createElement(TextComponent, { children: "\uD305\uCEE4\uBDF0\uC640 \uC5F0\uB3D9\uC774\uD6C4\uC5D0 \uACE0\uAC1D\uC774 \uC2A4\uC2A4\uB85C \uC608\uC57D,\uACB0\uC81C\uC2DC\uC5D0 \uCFE0\uD3F0\uC744 \uC801\uC6A9\uD558\uC5EC \uC774\uC6A9\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4." }),
                            React.createElement(TextComponent, { children: "\uD655\uC778\uD558\uC168\uC73C\uBA74, \uACC4\uC18D\uBC84\uD2BC\uC744 \uB20C\uB7EC \uC5F0\uB3D9\uC744 \uC9C4\uD589\uD574\uC8FC\uC138\uC694." })) })))
            });
            return [2 /*return*/];
        });
    }); };
    var onSubmit = form.handleSubmit(handleLogin, function (errors) {
        console.log('onSubmit errors', errors);
    });
    var _a = Modal.useModal(), modal = _a[0], modalContext = _a[1];
    return (React.createElement(Card, { title: React.createElement(TextComponent, { children: "\uB124\uC774\uBC84 \uC5F0\uB3D9 \uC815\uBCF4 \uC785\uB825", type: "headline3" }) },
        modalContext,
        React.createElement(Notice, { messages: [
                '- 타사관리프로그램을 통해 네이버에 연동되어있으면 이를 해지하고 등록해주세요.',
                '- 최초 연동시 현재 노출중인 상품들이 모두 미노출로 변경됩니다. 팅커뷰, 네이버간의 연동설정을 완료하시면 상품들이 다시 정상적으로 노출됩니다.',
                '- 팅커뷰와 연동시 "관리자 확인후 확정" 옵션이 "예약 신청 후 동시확정"으로 일괄 변경됩니다.'
            ] }),
        React.createElement(Form, { style: { marginTop: '20px' } },
            React.createElement(HookFormInputs.Text, { label: '네이버 로그인 아이디', controlProps: {
                    name: 'naverId',
                    control: form.control,
                    rules: {
                        required: '네이버 로그인 아이디를 입력해주세요.',
                        validate: function (value) {
                            if (value === null || value === void 0 ? void 0 : value.includes('@')) {
                                return '이메일 주소가아닌 네이버 아이디만 입력해주세요.';
                            }
                        }
                    }
                }, inputProps: {} }),
            React.createElement(HookFormInputs.Text, { label: '예약상점아이디', controlProps: {
                    name: 'bookingBusinessId',
                    control: form.control,
                    rules: {
                        required: '예약상점아이디를 입력해주세요.',
                        pattern: { value: /\d+/, message: '예약상점 아이디는 아래를 참조하여 숫자로 입력해주세요.' }
                    }
                }, inputProps: {} }),
            React.createElement(Button, { type: "primary", children: "\uC5F0\uB3D9", style: { width: '100%' }, onClick: form.handleSubmit(onSubmit) })),
        React.createElement(TextComponent, { marginTop: 20, children: React.createElement("div", null,
                React.createElement("a", { href: "https://new.smartplace.naver.com/", children: "1. \uC2A4\uB9C8\uD2B8\uD50C\uB808\uC774\uC2A4 \uAD00\uB9AC\uC790\uD398\uC774\uC9C0(https://new.smartplace.naver.com/)" }), " \uC811\uC18D \uD6C4") }),
        React.createElement(TextComponent, { children: "2. \uC5F0\uACB0\uD558\uACE0\uC790\uD558\uB294 \uC0C1\uC810 \uC120\uD0DD \uD6C4 \uC88C\uCE21\uC0AC\uC774\uB4DC\uBA54\uB274(\uC608\uC57D -> \uAE30\uBCF8\uC815\uBCF4 \uD074\uB9AD)", marginTop: 5 }),
        React.createElement(TextComponent, { children: "3. \uC544\uB798 \uC774\uBBF8\uC9C0\uC758 \uBE68\uAC04\uBC15\uC2A4 \uBD80\uBD84\uC758 \uC22B\uC790 \uBCF5\uC0AC \uD6C4 \uC608\uC57D\uC0C1\uC810\uC544\uC774\uB514\uC5D0 \uBD99\uC5EC\uB123\uAE30", marginTop: 5 }),
        React.createElement("img", { src: IMAGES.naver_booking_example, style: { width: '100%', marginBottom: '20px', marginTop: '20px' } })));
};
var Notice = function (props) {
    return (React.createElement(Alert, { message: "\uC77D\uC5B4\uC8FC\uC138\uC694!", description: React.createElement("div", null, props.messages.map(function (message, index) {
            return React.createElement(TextComponent, { key: index }, message);
        })), type: "info" }));
};
var NaverInformation = function (props) {
    var _a, _b, _c;
    var smartplace = (_a = props.store) === null || _a === void 0 ? void 0 : _a.preference.smartplace;
    var businessQuery = NaverLambdaQuery.useNaverBusinessById({ businessId: smartplace === null || smartplace === void 0 ? void 0 : smartplace.bookingBusinessId }, {
        enabled: !!(smartplace === null || smartplace === void 0 ? void 0 : smartplace.bookingBusinessId)
    });
    var storeName = ((_b = businessQuery === null || businessQuery === void 0 ? void 0 : businessQuery.data) === null || _b === void 0 ? void 0 : _b.name) || (smartplace === null || smartplace === void 0 ? void 0 : smartplace.storeName) || ((_c = props.store) === null || _c === void 0 ? void 0 : _c.name);
    var _d = Modal.useModal(), modal = _d[0], contextHolder = _d[1];
    var loader = useEntireLoader();
    var _e = React.useState(false), naverServiceInfoEditor = _e[0], setNaverServiceInfoEditor = _e[1];
    return (React.createElement(Layout.Stack, { gutter: 16 },
        React.createElement(Layout.FluidInline, { stretch: 0 },
            React.createElement(Row, { align: "middle", style: { cursor: 'pointer' }, onClick: function () {
                    setNaverServiceInfoEditor(true);
                } },
                React.createElement(TextComponent, { children: storeName, type: "headline3", style: { marginRight: '10px' } }),
                React.createElement(Icon, { name: "edit" })),
            React.createElement(Button, { color: "danger", children: "\uC5F0\uB3D9 \uD574\uC81C", style: { marginLeft: '5px', marginRight: '5px' }, onClick: function () {
                    modal.confirm({
                        centered: true,
                        title: '네이버 스마트플레이스 연동해제',
                        content: (React.createElement("div", null,
                            React.createElement(TextComponent, { children: "'\uC5F0\uB3D9\uC744 \uD574\uC81C\uD558\uC2DC\uBA74 \uC124\uC815\uC774 \uBAA8\uB450 \uCD08\uAE30\uD654\uB429\uB2C8\uB2E4. \uADF8\uB798\uB3C4 \uD574\uC81C\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?'", marginBottom: 20 }),
                            React.createElement(AlertComponent, { showIcon: true, message: "\uAF2D \uD655\uC778\uD558\uC138\uC694!", description: "\uC5F0\uB3D9 \uD574\uC81C \uD6C4 \uB124\uC774\uBC84 \uC0C1\uD488\uC774 '\uBBF8\uB178\uCD9C'\uB85C \uBCC0\uACBD\uB418\uC5B4 \uC608\uC57D\uC744 \uBC1B\uC744 \uC218 \uC5C6\uAC8C\uB429\uB2C8\uB2E4. \uC2A4\uB9C8\uD2B8 \uD50C\uB808\uC774\uC2A4\uC5D0\uC11C \uAF2D \uD655\uC778\uD574\uC8FC\uC138\uC694!" }))),
                        onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var err_1;
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        loader.show();
                                        _b.label = 1;
                                    case 1:
                                        _b.trys.push([1, 4, 5, 6]);
                                        return [4 /*yield*/, LambdaClient.getInstance().remove("naver/".concat((_a = props.store) === null || _a === void 0 ? void 0 : _a.id))];
                                    case 2:
                                        _b.sent();
                                        return [4 /*yield*/, StoreQuery.refreshMyStoreInCache()];
                                    case 3:
                                        _b.sent();
                                        return [3 /*break*/, 6];
                                    case 4:
                                        err_1 = _b.sent();
                                        notification.info({ message: err_1.message || '연동해제 실패' });
                                        return [3 /*break*/, 6];
                                    case 5:
                                        loader.hide();
                                        return [7 /*endfinally*/];
                                    case 6: return [2 /*return*/];
                                }
                            });
                        }); },
                        onCancel: function () { }
                    });
                } }),
            contextHolder),
        businessQuery.data && (React.createElement(NaverServiceInfoEditor, { bookingBusinessId: smartplace === null || smartplace === void 0 ? void 0 : smartplace.bookingBusinessId, business: businessQuery === null || businessQuery === void 0 ? void 0 : businessQuery.data, open: naverServiceInfoEditor, onClose: function () {
                setNaverServiceInfoEditor(false);
            } })),
        (smartplace === null || smartplace === void 0 ? void 0 : smartplace.bookingBusinessId) && React.createElement(RefundPolicyEditor, { bookingBusinessId: smartplace === null || smartplace === void 0 ? void 0 : smartplace.bookingBusinessId })));
};
var RefundPolicyEditor = function (props) {
    var _a;
    var form = useForm({ mode: 'onChange' });
    var refundQuery = NaverLambdaQuery.useRefundPolies({ businessId: props === null || props === void 0 ? void 0 : props.bookingBusinessId }, { enabled: !!(props === null || props === void 0 ? void 0 : props.bookingBusinessId) });
    var createPolicy = NaverLambdaQuery.useCreateRefundPolies();
    React.useEffect(function () {
        var _a;
        form.reset({
            policies: ((_a = refundQuery.data) === null || _a === void 0 ? void 0 : _a.policies) || [{ baseDay: 0, rate: 0 }],
            policyCode: 'RF01',
            description: '※ 올바른 예약문화 정착을 위해 불가피한 조치이오니 양해 부탁드립니다.'
        });
    }, [(_a = refundQuery.data) === null || _a === void 0 ? void 0 : _a.refundPolicyId]);
    var handleSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, createPolicy.mutate({
                            businessId: props === null || props === void 0 ? void 0 : props.bookingBusinessId,
                            policyCode: 'RF01',
                            description: values.description,
                            policies: values.policies || [{ baseDay: 0, rate: 0 }]
                        })];
                case 1:
                    _a.sent();
                    notification.success({ message: '저장되었습니다.' });
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    notification.info({ message: '오류가 발생했습니다.' });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    var policies = form.watch('policies');
    var isFull = (policies === null || policies === void 0 ? void 0 : policies.length) >= 11;
    return (React.createElement(Card, { loading: refundQuery.isLoading, title: "\uB124\uC774\uBC84\uC608\uC57D \uD658\uBD88\uAE30\uC900 \uC124\uC815", actions: [
            React.createElement(Layout.FluidInline, { justify: "end", style: { paddingRight: '20px' } },
                React.createElement(Button, { onClick: handleSubmit, disabled: !form.formState.isDirty && !form.formState.isValid, type: "primary" }, "\uC800\uC7A5"))
        ] },
        React.createElement(TextComponent, { children: "\uC2E4\uC81C \uC801\uC6A9\uD558\uACE0 \uACC4\uC2E0 \uD658\uBD88\uAE30\uC900\uC744 \uC785\uB825\uD574 \uC8FC\uC138\uC694. \uD574\uB2F9 \uAE30\uC900\uC5D0 \uC758\uD574 \uD658\uBD88 \uCC98\uB9AC \uB429\uB2C8\uB2E4. \uC124\uC815\uD558\uC2DC\uC9C0 \uC54A\uC740 \uB0A0\uC9DC\uB294 100% \uD658\uBD88 \uB429\uB2C8\uB2E4.", marginTop: 16, marginBottom: 16 }),
        React.createElement(Layout.Stack, { gutter: 8, style: { marginLeft: '10px' } }, policies === null || policies === void 0 ? void 0 : policies.map(function (refund, index) {
            return (React.createElement(Layout.FluidInline, { key: "".concat(index), gutter: 8, align: "start" },
                React.createElement(TextComponent, { type: "headline3", children: refund.baseDay ? "\uC608\uC57D ".concat(refund.baseDay, "\uC77C\uC804") : '예약 당일', style: { width: '100px' } }),
                React.createElement(Layout.Inline, { gutter: 8, align: "start" },
                    React.createElement(TextComponent, { type: "headline3", children: "\uCD1D\uAE09\uC561\uC758" }),
                    React.createElement("div", null,
                        React.createElement(HookFormInputs.NumberInput, { inputProps: { suffix: '%' }, controlProps: {
                                name: "policies.".concat(index, ".rate"),
                                control: form.control,
                                rules: {
                                    required: { value: true, message: '0이상의 환불율을 입력해주세요.' },
                                    min: { value: 0, message: '0이상의 환불율을 입력해주세요.' },
                                    max: { value: 100, message: '100이하의 환불율을 입력해주세요.' }
                                }
                            } }))),
                index === (policies === null || policies === void 0 ? void 0 : policies.length) - 1 && (React.createElement(Icon, { name: "close", size: 24, onClick: function () {
                        form.setValue("policies", policies === null || policies === void 0 ? void 0 : policies.filter(function (_, i) { return i !== index; }).filter(Boolean));
                    } }))));
        })),
        !isFull && (React.createElement(ButtonWithPlusCircle, { children: "\uD658\uBD88 \uAE30\uC900 \uCD94\uAC00", onClick: function () {
                return form.setValue("policies", policies === null || policies === void 0 ? void 0 : policies.concat({
                    baseDay: policies === null || policies === void 0 ? void 0 : policies.length,
                    rate: 0
                }));
            } }))));
};
var NaverServiceInfoEditor = function (props) {
    var form = useForm();
    React.useEffect(function () {
        form.reset(pick(props.business, 'serviceName', 'desc', 'isImp'));
    }, [props.business]);
    var handleReset = function () {
        form.reset(pick(props.business, 'serviceName', 'desc', 'isImp'));
    };
    var update = NaverLambdaQuery.useUpdateNaverBusiness();
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var result, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    return [4 /*yield*/, update.mutateAsync(__assign(__assign({}, values), { bookingBusinessId: props.bookingBusinessId }))];
                case 1:
                    result = _a.sent();
                    notification.info({ message: '네이버 서비스 정보가 수정되었습니다.' });
                    return [3 /*break*/, 4];
                case 2:
                    error_3 = _a.sent();
                    notification.info({ message: '네이버 서비스 정보 수정 중 오류가 발생했습니다.' });
                    return [3 /*break*/, 4];
                case 3:
                    props.onClose();
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    return (React.createElement(DrawerComponent, { open: props.open, title: "\uB124\uC774\uBC84 \uC11C\uBE44\uC2A4 \uC815\uBCF4 \uC218\uC815", footer: null, children: React.createElement("div", { style: { padding: '20px' } },
            React.createElement(FormContainer, { formElement: React.createElement("div", null,
                    React.createElement(HookFormInputs.Text, { label: "\uC11C\uBE44\uC2A4\uBA85", inputProps: {}, controlProps: {
                            name: "serviceName",
                            control: form.control,
                            rules: {
                                maxLength: {
                                    value: 30,
                                    message: '서비스명은 30자 이내로 입력해주세요.'
                                }
                            }
                        } }),
                    React.createElement(HookFormInputs.Textarea, { label: "\uC0C1\uC138\uC124\uBA85", inputProps: {}, controlProps: {
                            name: "desc",
                            control: form.control,
                            rules: {
                                maxLength: {
                                    value: 2000,
                                    message: '상세설명은 2000자 이내로 입력해주세요.'
                                },
                                minLength: {
                                    value: 20,
                                    message: '상세설명은 20자 이상으로 입력해주세요.'
                                }
                            }
                        } }),
                    React.createElement(Row, { gutter: 16, align: "middle", style: { marginTop: '10px' } },
                        React.createElement(Col, null,
                            React.createElement(HookFormInputs.SwitchInput, { inputProps: {
                                    checked: form.getValues().isImp
                                }, controlProps: {
                                    name: "isImp",
                                    control: form.control
                                } })),
                        React.createElement(TextComponent, { children: '서비스 예약 노출' }))), visible: !!form.formState.isDirty, onConfirm: form.handleSubmit(onSubmit), onCancel: handleReset })), onClose: function () {
            console.log('읭??');
            props.onClose();
        } }));
};
var BlockAvailableTime = function (props) {
    var _a, _b;
    var isPC = useWindowSize().isPC;
    var smartplace = (_a = props.store) === null || _a === void 0 ? void 0 : _a.preference.smartplace;
    var active = smartplace === null || smartplace === void 0 ? void 0 : smartplace.active;
    var deleteWhenCancel = get(smartplace, 'deleteWhenCancel');
    var shouldSendMessage = get(smartplace, 'shouldSendMessage');
    var loader = useEntireLoader();
    var handleReset = function () {
        form.reset(getDefaultValue());
    };
    var getDefaultValue = function () {
        var _a, _b, _c, _d, _e, _f;
        var blockSettings = (_b = (_a = props.store.preference) === null || _a === void 0 ? void 0 : _a.smartplace) === null || _b === void 0 ? void 0 : _b.blockSettings;
        var newValue = {
            isActive: blockSettings === null || blockSettings === void 0 ? void 0 : blockSettings.isActive,
            isBlockWhenCovered: (blockSettings === null || blockSettings === void 0 ? void 0 : blockSettings.isBlockWhenCovered) || false,
            isBlockWhenTransferMethod: (blockSettings === null || blockSettings === void 0 ? void 0 : blockSettings.isBlockWhenTransferMethod) || false,
            types: (blockSettings === null || blockSettings === void 0 ? void 0 : blockSettings.types) || ['reservation'],
            useStoreProduct: blockSettings === null || blockSettings === void 0 ? void 0 : blockSettings.useStoreProduct,
            allowPartialDuplicate: (blockSettings === null || blockSettings === void 0 ? void 0 : blockSettings.allowPartialDuplicate) || false,
            reservation: {
                beforeMinute: ((_c = blockSettings === null || blockSettings === void 0 ? void 0 : blockSettings.reservation) === null || _c === void 0 ? void 0 : _c.beforeMinute) || 0,
                afterMinute: ((_d = blockSettings === null || blockSettings === void 0 ? void 0 : blockSettings.reservation) === null || _d === void 0 ? void 0 : _d.afterMinute) || 0
            },
            schedule: {
                beforeMinute: ((_e = blockSettings === null || blockSettings === void 0 ? void 0 : blockSettings.schedule) === null || _e === void 0 ? void 0 : _e.beforeMinute) || 0,
                afterMinute: ((_f = blockSettings === null || blockSettings === void 0 ? void 0 : blockSettings.schedule) === null || _f === void 0 ? void 0 : _f.afterMinute) || 0
            }
        };
        return newValue;
    };
    React.useEffect(function () {
        handleReset();
    }, [props.store]);
    var form = useForm({
        defaultValues: getDefaultValue()
    });
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var blockSettings, newPreference, today_1, err_3, error_4;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    blockSettings = ((_b = (_a = props.store.preference) === null || _a === void 0 ? void 0 : _a.smartplace) === null || _b === void 0 ? void 0 : _b.blockSettings) || {};
                    newPreference = set(props.store.preference, "smartplace.blockSettings", __assign(__assign({}, (blockSettings || {})), values));
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 8, , 9]);
                    return [4 /*yield*/, props.handleUpdate({
                            id: props.store.id,
                            preference: newPreference
                        }, true)];
                case 2:
                    _d.sent();
                    if (!((_c = props.store) === null || _c === void 0 ? void 0 : _c.isOfficialNaver)) return [3 /*break*/, 7];
                    loader.show();
                    _d.label = 3;
                case 3:
                    _d.trys.push([3, 5, 6, 7]);
                    today_1 = dayjs();
                    return [4 /*yield*/, syncNaverCalendar({
                            store: props.store,
                            effectedDates: times(30).map(function (day) { return today_1.add(day, 'day').toDate(); })
                        }, true)];
                case 4:
                    _d.sent();
                    return [3 /*break*/, 7];
                case 5:
                    err_3 = _d.sent();
                    console.log('err', err_3);
                    return [3 /*break*/, 7];
                case 6:
                    notification.success({ message: '네이버연동 설정이 변경되었습니다.' });
                    loader.hide();
                    return [7 /*endfinally*/];
                case 7: return [3 /*break*/, 9];
                case 8:
                    error_4 = _d.sent();
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    var allowPartialDuplicate = form.watch('allowPartialDuplicate');
    var types = form.watch('types');
    var isBlockWhenCovered = form.watch('isBlockWhenCovered');
    var options = [
        { value: 'reservation', label: '예약', disabled: true },
        { value: 'schedule', label: '일정' },
        { value: 'off', label: '휴무' }
    ];
    var typeString = (types === null || types === void 0 ? void 0 : types.map(function (key) { return options.find(function (item) { return item.value === key; }).label; }).join(',')) || '';
    var timeOptions = [
        { value: 0, label: '사용안함' },
        { value: 30, label: '30분' },
        { value: 60, label: '1시간' },
        { value: 90, label: '1시간30분' },
        { value: 120, label: '2시간' }
    ];
    var addionalBlockTimeItems = [
        { key: 'reservation.beforeMinute', label: '예약 전 시간' },
        { key: 'reservation.afterMinute', label: '예약 후 시간' },
        types.includes('schedule') && { key: 'schedule.beforeMinute', label: '일정 전 시간' },
        types.includes('schedule') && { key: 'schedule.afterMinute', label: '일정 후 시간' }
    ].filter(Boolean);
    return (React.createElement(Layout.Stack, { gutter: 16 },
        React.createElement(Card, { title: "\uB124\uC774\uBC84 \uC608\uC57D \uAC00\uC838\uC624\uAE30", extra: React.createElement(Switch, { checked: !!active, onChange: function (checked) {
                    try {
                        props.handleUpdate({
                            id: props.store.id,
                            preference: set(props.store.preference, "smartplace.active", checked)
                        }, false);
                    }
                    catch (error) { }
                } }) },
            React.createElement(Row, { align: "middle", gutter: 24 },
                React.createElement(Col, null,
                    React.createElement(Switch, { checked: !!deleteWhenCancel, onChange: function (checked) {
                            try {
                                props.handleUpdate({
                                    id: props.store.id,
                                    preference: set(props.store.preference, "smartplace.deleteWhenCancel", checked)
                                }, false);
                            }
                            catch (error) { }
                        } })),
                React.createElement(Col, null,
                    React.createElement(TextComponent, { children: "\uB124\uC774\uBC84 \uC608\uC57D \uCDE8\uC18C \uC2DC \uD305\uCEE4\uBDF0\uC5D0\uC11C \uC608\uC57D \uC0AD\uC81C", type: "subtitle1", marginRight: 90 }))),
            React.createElement(Row, { align: "middle", gutter: 24, style: { marginTop: '10px' } },
                React.createElement(Col, null,
                    React.createElement(Switch, { checked: !!shouldSendMessage, onChange: function (checked) {
                            try {
                                props.handleUpdate({
                                    id: props.store.id,
                                    preference: set(props.store.preference, "smartplace.shouldSendMessage", checked)
                                }, false);
                            }
                            catch (error) { }
                        } })),
                React.createElement(Col, null,
                    React.createElement(TextComponent, { children: "\uB124\uC774\uBC84\uC608\uC57D \uBB38\uC790 \uC790\uB3D9\uBC1C\uC1A1", type: "subtitle1", marginRight: 90 })))),
        React.createElement(Card, { title: "\uB124\uC774\uBC84 \uC608\uC57D \uB9C9\uAE30", extra: React.createElement(HookFormInputs.SwitchInput, { inputProps: {
                    checked: form.getValues().isActive
                }, controlProps: {
                    name: "isActive",
                    control: form.control
                } }) },
            React.createElement(FormContainer, { formElement: React.createElement("div", null,
                    React.createElement(TextComponent, { children: "\uC801\uC6A9 \uD56D\uBAA9", type: "headline3", marginBottom: 10 }),
                    React.createElement(SubSettingWrapper, null,
                        React.createElement(Controller, { name: "types", control: form.control, rules: { required: true }, render: function (_a) {
                                var field = _a.field;
                                return React.createElement(Checkbox.Group, __assign({}, field, { options: options }));
                            } }),
                        React.createElement(TextComponent, { children: "- ".concat(typeString, "\uB4E4\uC774 \uC788\uB294 \uC2DC\uAC04\uB300\uC758 \uB124\uC774\uBC84 \uC608\uC57D\uC744 \uB9C9\uC2B5\uB2C8\uB2E4."), marginTop: 10, type: "caption1", color: COLORS.gray2 }),
                        React.createElement(TextComponent, { children: "- \uC77C\uC815\uC744 \uC120\uD0DD\uD558\uC9C0 \uC54A\uC73C\uBA74 \uC77C\uC815 \uC804\uD6C4 \uC2DC\uAC04 \uCD94\uAC00\uB9C9\uAE30 \uAE30\uB2A5\uC744 \uC0AC\uC6A9\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.", type: "caption1", color: COLORS.gray2 })),
                    React.createElement(TextComponent, { children: "\uB124\uC774\uBC84\uC608\uC57D \uAC00\uB2A5\uC2DC\uAC04 \uC124\uC815", type: "headline3", marginTop: 30, marginBottom: 10 }),
                    React.createElement(SubSettingWrapper, null,
                        React.createElement(Row, { align: "middle", gutter: 8, style: { marginTop: '10px' } },
                            React.createElement(Col, null,
                                React.createElement(HookFormInputs.SwitchInput, { controlProps: {
                                        control: form.control,
                                        name: 'allowPartialDuplicate'
                                    }, inputProps: {
                                        checked: allowPartialDuplicate
                                    } })),
                            React.createElement(Col, null,
                                React.createElement(TextComponent, { children: "\uB124\uC774\uBC84\uC608\uC57D \uC911\uBCF5 \uD5C8\uC6A9", type: "headline3", marginRight: 90 }))),
                        React.createElement(TextComponent, { children: form.watch('allowPartialDuplicate')
                                ? "- \uB124\uC774\uBC84 \uC608\uC57D \uC2DC\uAC04 \uAE30\uBCF8 \uB2E8\uC704(".concat(((_b = props === null || props === void 0 ? void 0 : props.store) === null || _b === void 0 ? void 0 : _b.naverIsHour) ? '1시간' : '30분', ") \uACF5\uBC31\uC2DC\uAC04\uC774 \uC788\uC73C\uBA74 \uC608\uC57D\uC744 \uD5C8\uC6A9\uD568")
                                : '- 시술시간을 고려하여 예약시간 겹치지 않을때만 예약을 허용함', marginTop: 10, marginBottom: 30, type: "caption1" }),
                        !!allowPartialDuplicate && (React.createElement(React.Fragment, null,
                            React.createElement(TextComponent, { children: "\uC2A4\uCF00\uC904 \uC804 \uD6C4 \uC2DC\uAC04 \uCD94\uAC00\uB9C9\uAE30", type: "subtitle2" }),
                            React.createElement(Layout.Columns, { columns: isPC ? 2 : 1, gutter: 8, style: { maxWidth: '400px' } }, addionalBlockTimeItems.map(function (item) {
                                return (React.createElement(Layout.Stack, null,
                                    React.createElement(HookFormInputs.SelectInput, { label: item.label, isHorizontal: false, controlProps: {
                                            name: item.key,
                                            control: form.control
                                        }, inputProps: {
                                            style: { width: '300px' },
                                            placeholder: '사용안함',
                                            options: timeOptions
                                        } })));
                            })))),
                        React.createElement(TextComponent, { children: "\uB9C9\uAE30 \uC870\uAC74 \uC124\uC815\uD558\uAE30 ", type: "subtitle2", marginTop: 20, marginBottom: 10 }),
                        React.createElement(HookFormInputs.SelectInput, { label: "\uC885\uB8CC\uC2DC\uAC04\uC774 30\uBD84 \uB610\uB294 00\uBD84\uC774 \uC544\uB2CC \uACBD\uC6B0 \uB9C9\uAE30", controlProps: {
                                name: "isBlockWhenCovered",
                                control: form.control
                            }, inputProps: {
                                style: { width: '300px' },
                                placeholder: '담당자 선택',
                                options: [
                                    { label: '예약시간 일부가 겹칠 때도 예약막기', value: false },
                                    { label: '시간 전체가 겹칠 때만 예약막기', value: true }
                                ]
                            } }),
                        React.createElement(TextComponent, { children: isBlockWhenCovered
                                ? '- 14:10~14:20 예약이 있더라도 14:00~14:30 동안 네이버 예약을 허용'
                                : '- 14:10~14:20 예약이 있으면 14:00~14:30 동안 네이버 예약을 금지', marginTop: 10, type: "subtitle2" }),
                        React.createElement(TextComponent, { children: isBlockWhenCovered ? '- 14:00~14:30 예약을 등록했다면 14:00~14:30 동안 네이버 예약을 금지' : '', type: "headline3", marginBottom: 20 }),
                        React.createElement(TextComponent, { children: "\uBB34\uD1B5\uC7A5 \uC785\uAE08\uC2DC \uC608\uC57D\uC2DC\uAC04 \uB9C9\uAE30 ", type: "subtitle2", marginTop: 20, marginBottom: 8 }),
                        React.createElement(TextComponent, { children: "\uACB0\uC81C\uC218\uB2E8\uC774 \uBB34\uD1B5\uC7A5 \uC785\uAE08\uC77C \uACBD\uC6B0 \uC608\uC57D\uC2DC\uAC04\uC774 \uB9C9\uD788\uC9C0 \uC54A\uC544 \uC911\uBCF5\uC608\uC57D\uC774 \uBC1C\uC0DD\uD558\uB294 \uACBD\uC6B0\uB97C \uBC29\uC9C0", type: "caption1", color: COLORS.gray1, marginBottom: 20 }),
                        React.createElement(HookFormInputs.SwitchInput, { controlProps: {
                                name: "isBlockWhenTransferMethod",
                                control: form.control
                            }, inputProps: {
                                style: { marginTop: '-30px' }
                            } }))), visible: !!form.formState.isDirty, onConfirm: form.handleSubmit(onSubmit), onCancel: handleReset }))));
};
var SubSettingWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 10px;\n  padding-left: 10px;\n"], ["\n  padding-top: 10px;\n  padding-left: 10px;\n"])));
var ProductOrManagerLink = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var storeProducts = ProductQuery.getProductsByStoreIdFromCache(props.store.id);
    var managerConnects = ((_a = props.store) === null || _a === void 0 ? void 0 : _a.managerConnectInfo) || {};
    var productConnects = ((_b = props.store) === null || _b === void 0 ? void 0 : _b.productConnectInfo) || {};
    var handleReset = function () {
        var newValues = {};
        if (props.type === 'bizItems') {
            Object.keys(managerConnects)
                .filter(function (bizItemId) { var _a; return (_a = props.items.map(function (item) { return Number(item.id); })) === null || _a === void 0 ? void 0 : _a.includes(Number(bizItemId)); })
                .map(function (key) {
                var _a, _b;
                newValues[key] = {
                    agencyKey: (_a = managerConnects[key]) === null || _a === void 0 ? void 0 : _a.agencyKey,
                    isImp: (_b = props === null || props === void 0 ? void 0 : props.items.find(function (item) { return Number(item.id) === Number(key); })) === null || _b === void 0 ? void 0 : _b.isImp
                };
            });
        }
        else {
            Object.keys(productConnects)
                .filter(function (optionId) { var _a; return (_a = props.items.map(function (item) { return Number(item.id); })) === null || _a === void 0 ? void 0 : _a.includes(Number(optionId)); })
                .map(function (key) {
                var _a;
                newValues[key] = {
                    agencyKey: (_a = productConnects[key]) === null || _a === void 0 ? void 0 : _a.agencyKey
                };
            });
        }
        form.reset(newValues);
    };
    React.useEffect(function () {
        handleReset();
    }, [props.activeKey]);
    React.useEffect(function () {
        handleReset();
    }, [props.items]);
    var form = useForm();
    var managers = ManagerQuery.getWorkingManagersInCache();
    var _g = React.useState(), selected = _g[0], setSelected = _g[1];
    var mapping = NaverLambdaQuery.useMappingItems();
    var loader = useEntireLoader();
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var error_5;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    loader.show();
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, mapping.mutateAsync({ storeId: (_a = props.store) === null || _a === void 0 ? void 0 : _a.id, mappings: values, type: props.type })];
                case 2:
                    _b.sent();
                    StoreQuery.refreshMyStoreInCache();
                    notification.info({ message: '네이버 설정이 수정되었습니다.' });
                    return [3 /*break*/, 5];
                case 3:
                    error_5 = _b.sent();
                    notification.error({ message: error_5.message || '네이버 설정 중 오류가 발생했습니다.' });
                    return [3 /*break*/, 5];
                case 4:
                    loader.hide();
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
        loader.hide();
    });
    var values = form.watch();
    return (React.createElement(React.Fragment, null,
        React.createElement(AlertComponent, { style: { marginTop: '20px', marginBottom: '20px' }, message: "\uC77D\uC5B4\uC8FC\uC138\uC694 \uD83D\uDECE", description: props.type === 'bizItems' ? (React.createElement("div", null,
                React.createElement(TextComponent, { children: "1\uFE0F\u20E3 \uB124\uC774\uBC84\uC608\uC57D\uC0C1\uC758 \uC608\uC57D\uC0C1\uD488\uC740 \uD305\uCEE4\uBDF0\uB0B4\uC758 \uB2F4\uB2F9\uC790(\uC9C1\uC6D0)\uC640 \uB9E4\uCE6D\uB418\uB294 \uAC1C\uB150\uC785\uB2C8\uB2E4." }),
                React.createElement(TextComponent, { children: "2\uFE0F\u20E3 \uC774 \uD398\uC774\uC9C0\uC5D0\uC11C\uB294 \uB124\uC774\uBC84 \uC608\uC57D\uC0C1\uD488\uACFC \uD305\uCEE4\uBDF0\uC758 \uB2F4\uB2F9\uC790\uB97C \uC5F0\uACB0 \uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4." }),
                React.createElement(TextComponent, { type: "headline3", children: "3\uFE0F\u20E3 \uD305\uCEE4\uBDF0 \uB2EC\uB825\uACFC \uB124\uC774\uBC84 \uC608\uC57D \uAC00\uB2A5\uC2DC\uAC04\uC744 \uB9DE\uCD94\uAE30 \uC704\uD55C \uACFC\uC815\uC774\uB2C8 \uAF2D \uC5F0\uACB0\uD574\uC8FC\uC138\uC694 \uD83D\uDE4F" }),
                React.createElement(TextComponent, { children: "4\uFE0F\u20E3 \uC608\uC57D\uC0C1\uD488\uBA85\uC744 \uD074\uB9AD\uD558\uBA74 \uB124\uC774\uBC84 \uC608\uC57D\uC0C1\uD488\uC758 \uC815\uBCF4, \uC77C\uC815, \uD734\uBB34, \uC5F0\uACB0\uBA54\uB274 \uC124\uC815 \uC77C\uBD80\uAC00 \uAC00\uB2A5\uD569\uB2C8\uB2E4." }),
                React.createElement(TextComponent, { children: "5\uFE0F\u20E3 \uC9C1\uC6D0 \uC5F0\uACB0 \uD6C4 \uB178\uCD9C\uC124\uC815\uC744 ON\uC73C\uB85C \uBCC0\uACBD\uD558\uC154\uC57C \uB124\uC774\uBC84 \uC608\uC57D\uD398\uC774\uC9C0\uC5D0 \uB178\uCD9C\uB429\uB2C8\uB2E4.", type: "headline3" }))) : (React.createElement("div", null,
                React.createElement(TextComponent, { children: "1\uFE0F\u20E3 \uB124\uC774\uBC84\uC608\uC57D\uC0C1\uC758 \uC2DC\uC220\uBA54\uB274\uB294 \uD305\uCEE4\uBDF0\uB0B4\uC758 \uC0C1\uD488\uACFC \uB9E4\uCE6D\uB418\uB294 \uAC1C\uB150\uC785\uB2C8\uB2E4." }),
                React.createElement(TextComponent, { children: "2\uFE0F\u20E3 \uC774 \uD398\uC774\uC9C0\uC5D0\uC11C\uB294 \uB124\uC774\uBC84 \uC2DC\uC220\uBA54\uB274\uC640 \uD305\uCEE4\uBDF0\uC758 \uC0C1\uD488\uC744 \uC5F0\uACB0 \uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4." }),
                React.createElement(TextComponent, { children: "3\uFE0F\u20E3 \uD305\uCEE4\uBDF0 \uB2EC\uB825\uC5D0 \uC2DC\uC220\uB0B4\uC6A9 \uBC0F \uC2DC\uC220 \uC2DC\uAC04\uC744 \uBC18\uC601\uD558\uAE30 \uC704\uD55C \uC808\uCC28\uAE30 \uB54C\uBB38\uC5D0 \uAF2D \uC5F0\uACB0\uD574\uC8FC\uC138\uC694\uD83D\uDE4F", type: "headline3" }),
                React.createElement(TextComponent, { children: "4\uFE0F\u20E3 \uC2DC\uC220\uBA54\uB274\uB97C \uD074\uB9AD\uD558\uBA74 \uB124\uC774\uBC84 \uC2DC\uC220\uBA54\uB274\uC758 \uC815\uBCF4\uB97C \uC77C\uBD80 \uC218\uC815\uD558\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4." }),
                React.createElement(TextComponent, { children: "5\uFE0F\u20E3 \uC2DC\uC220\uBA54\uB274 \uB4F1\uB85D \uBC84\uD2BC\uC744 \uD1B5\uD574 \uB124\uC774\uBC84 \uC2DC\uC220\uBA54\uB274\uB97C \uD305\uCEE4\uBDF0\uC5D0\uC11C \uCD94\uAC00\uD558\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4." }))), type: "info" }),
        props.type === 'options' && (React.createElement(Layout.FluidInline, { justify: "end" },
            React.createElement(Button, { type: "primary", children: "\uB124\uC774\uBC84 \uC2DC\uC220\uBA54\uB274 \uCD94\uAC00", onClick: function () {
                    var _a, _b, _c;
                    setSelected({
                        optionId: createUUID(),
                        businessId: (_c = (_b = (_a = props.store) === null || _a === void 0 ? void 0 : _a.preference) === null || _b === void 0 ? void 0 : _b.smartplace) === null || _c === void 0 ? void 0 : _c.bookingBusinessId
                    });
                } }))),
        React.createElement(FormContainer, { formElement: (_c = props.items) === null || _c === void 0 ? void 0 : _c.map(function (product) {
                var _a, _b, _c;
                return (React.createElement(Row, { align: "middle" },
                    React.createElement(FluidSelect, { inputProps: {
                            filterSort: function (optionA, optionB) {
                                return optionA.label.localeCompare(optionB.label.toLowerCase());
                            },
                            filterOption: function (input, option) {
                                if (option &&
                                    option.label &&
                                    option.label.includes &&
                                    option.label.includes(input)) {
                                    return true;
                                }
                                else {
                                    return false;
                                }
                            },
                            showSearch: true,
                            allowClear: true,
                            options: props.type === 'bizItems'
                                ? managers === null || managers === void 0 ? void 0 : managers.map(function (manager) {
                                    return {
                                        value: manager.id,
                                        label: manager.displayName
                                    };
                                })
                                : orderBy((_a = storeProducts === null || storeProducts === void 0 ? void 0 : storeProducts.products) === null || _a === void 0 ? void 0 : _a.map(function (storeProduct) {
                                    return {
                                        value: storeProduct.id,
                                        label: storeProduct.nameWithGroupName
                                    };
                                }), 'label', 'desc')
                        }, label: React.createElement("div", { style: { width: '200px', marginLeft: '10px', textDecoration: 'underline', cursor: 'pointer' }, onClick: function () {
                                var _a, _b, _c;
                                var businessId = (_c = (_b = (_a = props.store) === null || _a === void 0 ? void 0 : _a.preference) === null || _b === void 0 ? void 0 : _b.smartplace) === null || _c === void 0 ? void 0 : _c.bookingBusinessId;
                                setSelected(props.type === 'bizItems'
                                    ? {
                                        businessId: businessId,
                                        bizItemId: product.id
                                    }
                                    : {
                                        businessId: businessId,
                                        optionId: product.id
                                    });
                            } },
                            product.label,
                            React.createElement("img", { src: IMAGES.schedule_icon_naver, style: { marginLeft: '5px' } })), key: product.id, controlProps: {
                            control: form.control,
                            name: "".concat(product.id, ".agencyKey")
                            // rules: { required: '메니저를 선택해주세요.' }
                        } }),
                    props.type === 'bizItems' && (React.createElement(HookFormInputs.SwitchInput, { memoDeps: (_b = values[product === null || product === void 0 ? void 0 : product.id]) === null || _b === void 0 ? void 0 : _b.isImp, controlProps: {
                            control: form.control,
                            name: "".concat(product.id, ".isImp"),
                            rules: {
                                validate: function (value) {
                                    var _a;
                                    var item = form.getValues();
                                    if (!((_a = item[product.id]) === null || _a === void 0 ? void 0 : _a.agencyKey) && value) {
                                        return '노출 설정을 켜기위해서는 직원를 연결해주세요.';
                                    }
                                    return true;
                                }
                            }
                        }, inputProps: {
                            checked: (_c = values[product === null || product === void 0 ? void 0 : product.id]) === null || _c === void 0 ? void 0 : _c.isImp
                        }, label: React.createElement(TextComponent, { children: "\uB178\uCD9C\uC124\uC815", color: COLORS.gray3, marginRight: 10 }) }))));
            }), visible: !!form.formState.isDirty, onConfirm: form.handleSubmit(onSubmit), onCancel: handleReset }),
        (selected === null || selected === void 0 ? void 0 : selected.bizItemId) && (React.createElement(BizItemEditor, { bizItemId: selected.bizItemId, businessId: selected.businessId, store: props.store, onClose: function () { return setSelected(null); } })),
        (selected === null || selected === void 0 ? void 0 : selected.optionId) && (React.createElement(NaverOptionEditor, { optionId: selected.optionId, businessId: selected.businessId, store: props.store, onClose: function () { return setSelected(null); }, option: ((_e = (_d = props.data) === null || _d === void 0 ? void 0 : _d.options) === null || _e === void 0 ? void 0 : _e.find(function (item) { return item.optionId === selected.optionId; })) || {}, categories: (_f = props.data) === null || _f === void 0 ? void 0 : _f.categories }))));
};
var NaverOptionEditor = function (props) {
    var _a;
    var isUpdated = typeof props.optionId !== 'string';
    var optionId = props.optionId, businessId = props.businessId;
    var optionUpdate = NaverLambdaQuery.useUpdateNaverOption();
    var optionCreate = NaverLambdaQuery.useCreateNaverOption();
    var optionDelete = NaverLambdaQuery.useDeleteNaverOption();
    var basicForm = useForm({ mode: 'onChange' });
    var onSubmit = basicForm.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var optionId_1, rest, error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    if (!!isUpdated) return [3 /*break*/, 2];
                    optionId_1 = values.optionId, rest = __rest(values, ["optionId"]);
                    return [4 /*yield*/, optionCreate.mutateAsync(__assign({ businessId: businessId, minBookingCount: 1, stock: 9999 }, rest))];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, optionUpdate.mutateAsync(omit(__assign({ businessId: businessId, optionId: optionId }, values), 'inspectionStatusCode'))];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    notification.info({ message: '시술메뉴가 변경 되었습니다.' });
                    return [3 /*break*/, 6];
                case 5:
                    error_6 = _a.sent();
                    notification.error({ message: error_6.message || '시술메뉴 수정 중 오류가 발생했습니다.' });
                    return [3 /*break*/, 6];
                case 6:
                    props.onClose();
                    return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    var price = basicForm.watch('price');
    var _b = Modal.useModal(), modal = _b[0], context = _b[1];
    React.useEffect(function () {
        var _a, _b;
        if (!props.categories[0])
            return;
        if (isUpdated) {
            var cetegory = (_a = props.categories) === null || _a === void 0 ? void 0 : _a.find(function (category) { var _a; return !!((_a = category.options) === null || _a === void 0 ? void 0 : _a.find(function (option) { return option.optionId === props.option.optionId; })); });
            basicForm.reset(__assign({ categoryId: Number(cetegory === null || cetegory === void 0 ? void 0 : cetegory.categoryId) }, props.option));
        }
        else {
            basicForm.reset({ categoryId: Number((_b = props.categories[0]) === null || _b === void 0 ? void 0 : _b.categoryId) });
        }
    }, [optionId]);
    return (React.createElement(DrawerComponent, { open: true, onClose: props.onClose, footer: isUpdated && (React.createElement(Row, null,
            React.createElement(Button, { type: "primary", danger: true, children: "\uC2DC\uC220\uBA54\uB274 \uC0AD\uC81C", style: { width: '100%' }, onClick: function () {
                    modal.confirm({
                        title: '시술메뉴 삭제',
                        zIndex: 10000,
                        content: '상품을 삭제하시면 복구할 수 없습니다. 그래도 삭제하시겠습니까?',
                        centered: true,
                        okText: '삭제',
                        okButtonProps: { danger: true, children: '삭제' },
                        onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var error_7;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, optionDelete.mutateAsync({ businessId: businessId, optionId: optionId })];
                                    case 1:
                                        _a.sent();
                                        notification.info({ message: '시술메뉴가 삭제되었습니다.' });
                                        return [3 /*break*/, 3];
                                    case 2:
                                        error_7 = _a.sent();
                                        notification.error({ message: error_7.message || '시술메뉴 중 오류가 발생했습니다.' });
                                        return [3 /*break*/, 3];
                                    case 3:
                                        props.onClose();
                                        return [2 /*return*/];
                                }
                            });
                        }); }
                    });
                } }),
            context)), title: ((_a = props.option) === null || _a === void 0 ? void 0 : _a.name) || '시술메뉴 등록', children: React.createElement(Layout.Stack, { gutter: 16 },
            React.createElement(Card, { title: "\uAE30\uBCF8 \uC815\uBCF4", bordered: false, size: "small" },
                React.createElement(HookFormInputs.SelectInput, { inputProps: {
                        style: { width: '100%' },
                        options: props.categories.map(function (item) { return ({
                            label: item.name,
                            value: item.categoryId
                        }); })
                    }, label: "\uC2DC\uC220 \uCE74\uD14C\uACE0\uB9AC", controlProps: {
                        control: basicForm.control,
                        name: 'categoryId',
                        rules: {
                            required: '카테고리를 선택해주세요.'
                        }
                    } }),
                React.createElement(HookFormInputs.Text, { inputProps: {}, label: "\uC2DC\uC220\uBA54\uB274\uBA85", controlProps: {
                        control: basicForm.control,
                        name: 'name',
                        rules: {
                            required: '시술메뉴명 입력해주세요.',
                            maxLength: {
                                value: 30,
                                message: '시술메뉴명 30자 이내로 입력해주세요.'
                            }
                        }
                    } }),
                React.createElement(HookFormInputs.Textarea, { inputProps: {}, label: "\uC0C1\uD488 \uC18C\uAC1C", controlProps: {
                        control: basicForm.control,
                        name: 'desc',
                        rules: {
                            maxLength: {
                                value: 300,
                                message: '상품소개는 300자 이내로 입력해주세요.'
                            }
                        }
                    } }),
                React.createElement(HookFormInputs.NumberInput, { inputProps: {
                        suffix: '원'
                    }, label: "\uC815\uAC00", controlProps: {
                        control: basicForm.control,
                        name: 'normalPrice',
                        rules: {
                            deps: ['price'],
                            min: {
                                value: price,
                                message: '판매가보다 높은 정가를 입력해주세요.'
                            }
                        }
                    } }),
                React.createElement(HookFormInputs.NumberInput, { inputProps: {
                        suffix: '원'
                    }, label: "\uD310\uB9E4\uAC00", controlProps: {
                        control: basicForm.control,
                        name: 'price',
                        rules: {
                            deps: ['normalPrice'],
                            required: '판매가를 입력해주세요.'
                        }
                    } }),
                React.createElement(HookFormInputs.DatePicker, { label: "\uC6B4\uC601\uAE30\uAC04 \uC2DC\uC791\uC77C", controlProps: {
                        control: basicForm.control,
                        name: 'startDate',
                        rules: {
                            deps: ['endDate'],
                            validate: function (value) {
                                var endDate = basicForm.getValues('endDate');
                                if (endDate && !value) {
                                    return '종료일이 있으면 시작일을 입력해야합니다.';
                                }
                                return true;
                            }
                        }
                    } }),
                React.createElement(HookFormInputs.DatePicker, { label: "\uC6B4\uC601\uAE30\uAC04 \uC885\uB8CC\uC77C", controlProps: {
                        control: basicForm.control,
                        name: 'endDate',
                        rules: {
                            deps: ['startDate'],
                            validate: function (value) {
                                var startDate = basicForm.getValues('startDate');
                                if (startDate && !value) {
                                    return '시작일이 있으면 종료일을 입력해야합니다.';
                                }
                                return true;
                            }
                        }
                    } }),
                React.createElement(Row, { justify: 'end', style: { marginTop: '10px' }, gutter: 8 },
                    React.createElement(Button, { color: "priamry", children: "\uC800\uC7A5", type: "primary", onClick: onSubmit, disabled: !(basicForm.formState.isValid && basicForm.formState.isDirty) })))) }));
};
var BizItemEditor = function (props) {
    var _a, _b;
    var bizItemId = props.bizItemId, businessId = props.businessId;
    var bizItemQuery = NaverLambdaQuery.useBizItem({ businessId: businessId, bizItemId: bizItemId }, { enabled: !!bizItemId });
    var schedulesQuery = NaverLambdaQuery.useNaverSchedules({ businessId: businessId, bizItemId: bizItemId }, { enabled: !!bizItemId });
    var naverHolidayQuery = NaverLambdaQuery.useNaverHolidaySchedules({ businessId: businessId, bizItemId: bizItemId }, { enabled: !!bizItemId });
    var isLoading = bizItemQuery.isLoading || schedulesQuery.isLoading || naverHolidayQuery.isLoading;
    var bizItemUpdate = NaverLambdaQuery.useUpdateNaverBizItem();
    var bizItemDelete = NaverLambdaQuery.useDeleteNaverBizItem();
    var basicForm = useForm({ mode: 'onChange' });
    var onSubmit = basicForm.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var error_8;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, bizItemUpdate.mutateAsync(__assign({ businessId: businessId, bizItemId: bizItemId }, values))];
                case 1:
                    _a.sent();
                    notification.info({ message: '상품이 수정되었습니다.' });
                    return [3 /*break*/, 3];
                case 2:
                    error_8 = _a.sent();
                    notification.error({ message: error_8.message || '상품이 수정 중 오류가 발생했습니다.' });
                    return [3 /*break*/, 3];
                case 3:
                    props.onClose();
                    return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    var _c = Modal.useModal(), modal = _c[0], context = _c[1];
    React.useEffect(function () {
        var _a, _b;
        basicForm.reset({ name: (_a = bizItemQuery.data) === null || _a === void 0 ? void 0 : _a.name, desc: (_b = bizItemQuery.data) === null || _b === void 0 ? void 0 : _b.desc });
    }, [bizItemQuery.data]);
    var _d = React.useState(), naverScheduleData = _d[0], setNaverScheduleData = _d[1];
    var notBasicSchdules = ((_a = schedulesQuery.data) === null || _a === void 0 ? void 0 : _a.filter(function (schedule) { return !schedule.isBasicSchedule; })) || [];
    return (React.createElement(DrawerComponent, { open: true, onClose: props.onClose, footer: React.createElement(Row, null,
            React.createElement(EntireLoader, { open: isLoading }),
            React.createElement(Button, { type: "primary", danger: true, children: "\uC0C1\uD488 \uC0AD\uC81C", style: { width: '100%' }, onClick: function () {
                    modal.confirm({
                        title: '상품 삭제',
                        zIndex: 10000,
                        content: '상품을 삭제하시면 복구할 수 없습니다. 그래도 삭제하시겠습니까?',
                        centered: true,
                        okText: '삭제',
                        okButtonProps: { danger: true, children: '삭제' },
                        onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var error_9;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, bizItemDelete.mutateAsync({ businessId: businessId, bizItemId: bizItemId })];
                                    case 1:
                                        _a.sent();
                                        notification.info({ message: '상품이 삭제되었습니다.' });
                                        return [3 /*break*/, 3];
                                    case 2:
                                        error_9 = _a.sent();
                                        notification.error({ message: error_9.message || '상품삭제 중 오류가 발생했습니다.' });
                                        return [3 /*break*/, 3];
                                    case 3:
                                        props.onClose();
                                        return [2 /*return*/];
                                }
                            });
                        }); }
                    });
                } }),
            context), title: (_b = bizItemQuery === null || bizItemQuery === void 0 ? void 0 : bizItemQuery.data) === null || _b === void 0 ? void 0 : _b.name, children: React.createElement(Layout.Stack, { gutter: 16 },
            React.createElement(Card, { title: "\uAE30\uBCF8 \uC815\uBCF4", bordered: false, size: "small" },
                React.createElement(HookFormInputs.Text, { inputProps: {}, label: "\uC0C1\uD488\uBA85", controlProps: {
                        control: basicForm.control,
                        name: 'name',
                        rules: {
                            required: '상품명을 입력해주세요.',
                            maxLength: {
                                value: 30,
                                message: '상품명은 30자 이내로 입력해주세요.'
                            }
                        }
                    } }),
                React.createElement(HookFormInputs.Textarea, { inputProps: {}, label: "\uC0C1\uD488 \uC18C\uAC1C", controlProps: {
                        control: basicForm.control,
                        name: 'desc',
                        rules: {
                            required: '상품소개를 입력해주세요.',
                            maxLength: {
                                value: 1000,
                                message: '상품소개는 1000자 이내로 입력해주세요.'
                            },
                            minLength: {
                                value: 20,
                                message: '상품소개는 20자 이상 입력해주세요.'
                            }
                        }
                    } }),
                React.createElement(Row, { justify: 'end', style: { marginTop: '10px' }, gutter: 8 },
                    React.createElement(Button, { color: "priamry", children: "\uC800\uC7A5", type: "primary", onClick: onSubmit, disabled: !(basicForm.formState.isValid && basicForm.formState.isDirty) }))),
            React.createElement(Card, { title: "\uC784\uC2DC\uC6B4\uC601", bordered: false, size: "small", extra: React.createElement(ButtonWithPlusCircle, { children: "", onClick: function () { return setNaverScheduleData({}); } }) },
                (notBasicSchdules === null || notBasicSchdules === void 0 ? void 0 : notBasicSchdules.length) === 0 ? (React.createElement(Empty, { description: "\uC784\uC2DC \uC6B4\uC601\uC744 \uC124\uC815\uD574\uC8FC\uC138\uC694." })) : (notBasicSchdules.map(function (item) {
                    var rangeString = item.startDate === item.endDate ? item.startDate : "".concat(item.startDate, " ~ ").concat(item.endDate);
                    var hourString = formatOperatingHours(item.hourBit);
                    return (React.createElement(Layout.FluidInline, { key: item.scheduleId, gutter: 16, justify: "start", stretch: 0, style: { marginBottom: '10px' }, onClick: function () { return setNaverScheduleData(item); } },
                        React.createElement(Layout.Stack, null,
                            React.createElement(TextComponent, { children: rangeString, color: COLORS.gray3 }),
                            React.createElement(TextComponent, { children: daysOfWeek(item.weekdays), color: COLORS.gray3 })),
                        React.createElement(Layout.Stack, null, isEmpty(hourString)
                            ? '휴무'
                            : hourString.map(function (item) {
                                return React.createElement(TextComponent, { children: item, key: item, type: "headline3" });
                            }))));
                })),
                React.createElement(TemporalScheduleEditor, { schedule: naverScheduleData, onClose: function () { return setNaverScheduleData(null); }, store: props.store, businessId: props.businessId, bizItemId: props.bizItemId })),
            React.createElement(NaverHolidayDisplay, { store: props.store, businessId: props.businessId, bizItemId: props.bizItemId, holidaySchedules: naverHolidayQuery.data || [] })) }));
};
var TemporalScheduleEditor = function (props) {
    var _a, _b, _c, _d, _e;
    var isUpdated = (_a = props.schedule) === null || _a === void 0 ? void 0 : _a.scheduleId;
    var form = useForm({ mode: 'onChange' });
    var values = form.watch();
    var isHour = (_b = props.store) === null || _b === void 0 ? void 0 : _b.naverIsHour;
    var deleteQuery = NaverLambdaQuery.useDeleteNaverSchedule();
    var create = NaverLambdaQuery.useCreateNaverSchedule();
    var update = NaverLambdaQuery.useUpdatetNaverSchedule();
    var convertNaverScheduleToFormData = function (schedule) {
        return {
            start: [dayjs(schedule.startDate), dayjs(schedule.endDate)],
            weekdays: schedule.weekdays,
            hourBit: Array.from(schedule.hourBit).map(function (item) { return item === '1'; })
        };
    };
    var ALL_ENABLED = Array.from({ length: isHour ? 24 : 48 }, function () { return true; });
    var ALL_DISABLED = Array.from({ length: isHour ? 24 : 48 }, function () { return false; });
    React.useEffect(function () {
        var _a;
        if ((_a = props.schedule) === null || _a === void 0 ? void 0 : _a.startDate) {
            form.reset(convertNaverScheduleToFormData(props.schedule));
        }
        else {
            form.reset({
                hourBit: ALL_ENABLED
            });
        }
    }, [props.schedule]);
    var handleOK = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var func, isBusinessDay, err_4;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, 3, 4]);
                    func = isUpdated ? update : create;
                    isBusinessDay = !values.hourBit.find(function (item) { return item === '1'; });
                    return [4 /*yield*/, func.mutateAsync(omitEmptyValues({
                            businessId: props.businessId,
                            bizItemId: props.bizItemId,
                            scheduleId: (_a = props.schedule) === null || _a === void 0 ? void 0 : _a.scheduleId,
                            storeId: (_b = props === null || props === void 0 ? void 0 : props.store) === null || _b === void 0 ? void 0 : _b.id,
                            startDate: values === null || values === void 0 ? void 0 : values.start[0].format('YYYY-MM-DD'),
                            endDate: values.start[1].format('YYYY-MM-DD'),
                            weekdays: values.weekdays,
                            hourBit: values.hourBit.map(function (item) { return (item ? '1' : '0'); }).join(''),
                            isBusinessDay: isBusinessDay,
                            price: isBusinessDay ? 0 : null,
                            stock: isBusinessDay ? 1 : null
                        }))];
                case 1:
                    _c.sent();
                    notification.success({ message: "\uC77C\uC815 ".concat(isUpdated ? '수정이' : '생성이', " \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.") });
                    return [3 /*break*/, 4];
                case 2:
                    err_4 = _c.sent();
                    notification.success({ message: err_4.message || "\uC77C\uC815 ".concat(isUpdated ? '수정' : '생성', " \uC911 \uC624\uB958 \uBC1C\uC0DD") });
                    return [3 /*break*/, 4];
                case 3:
                    props.onClose();
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var _f = Modal.useModal(), deleteModal = _f[0], context = _f[1];
    var handleDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            deleteModal.confirm({
                title: '일정 삭제',
                centered: true,
                zIndex: 10001,
                content: '일정을 삭제하면 복구할 수 없습니다. 삭제하시겠습니까?',
                onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var err_5;
                    var _a, _b;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                _c.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, deleteQuery.mutateAsync({
                                        businessId: props.businessId,
                                        bizItemId: props.bizItemId,
                                        scheduleId: (_a = props.schedule) === null || _a === void 0 ? void 0 : _a.scheduleId,
                                        storeId: (_b = props === null || props === void 0 ? void 0 : props.store) === null || _b === void 0 ? void 0 : _b.id
                                    })];
                            case 1:
                                _c.sent();
                                notification.success({ message: '일정 삭제가 완료되었습니다.' });
                                return [3 /*break*/, 3];
                            case 2:
                                err_5 = _c.sent();
                                notification.success({ message: err_5.message || '일정 삭제 중 오류 발생' });
                                return [3 /*break*/, 3];
                            case 3:
                                props.onClose();
                                return [2 /*return*/];
                        }
                    });
                }); }
            });
            return [2 /*return*/];
        });
    }); };
    var start = form.watch('start');
    var availableDays = getDayNamessBetweenDates(start === null || start === void 0 ? void 0 : start[0], start === null || start === void 0 ? void 0 : start[1]);
    return (React.createElement(BigTitleModal, { open: !!props.schedule, centered: true, onOk: form.handleSubmit(handleOK), onCancel: props.onClose, title: "\uC784\uC2DC\uC6B4\uC601 ".concat(isUpdated ? '수정' : '작성'), children: React.createElement(Layout.Stack, { gutter: 8, style: { padding: '20px', width: '100%' } },
            context,
            React.createElement(HookFormInputs.RangePicker, { label: "\uC801\uC6A9 \uAE30\uAC04", controlProps: {
                    control: form.control,
                    name: 'start',
                    rules: { required: true, validate: function (value) { return ((value === null || value === void 0 ? void 0 : value.length) === 2 ? true : '날짜를 입력해주세요.'); } }
                }, inputProps: {
                    disabledDate: function (current) {
                        return dayjs().isAfter(current, 'day');
                    }
                } }),
            ((_c = values.start) === null || _c === void 0 ? void 0 : _c.length) === 2 && (React.createElement(HookFormInputs.SelectInput, { label: "\uC801\uC6A9 \uC694\uC77C", controlProps: {
                    control: form.control,
                    deps: ['start'],
                    name: 'weekdays',
                    rules: {
                        required: true,
                        validate: function (value) {
                            if ((value === null || value === void 0 ? void 0 : value.length) === 0) {
                                return '적용요일을 선택 해주세요.';
                            }
                            var isInvalid = some(value, function (day) {
                                return !availableDays.includes(day);
                            });
                            if (isInvalid) {
                                return '적용기간에 포함되지 않는 요일이 포함되어 있습니다.';
                            }
                            else {
                                return true;
                            }
                            // if()
                        }
                    }
                }, inputProps: {
                    style: { width: '100%' },
                    options: [
                        {
                            label: '월',
                            value: 'mon'
                        },
                        {
                            label: '화',
                            value: 'tue'
                        },
                        {
                            label: '수',
                            value: 'wed'
                        },
                        {
                            label: '목',
                            value: 'thu'
                        },
                        {
                            label: '금',
                            value: 'fri'
                        },
                        {
                            label: '토',
                            value: 'sat'
                        },
                        {
                            label: '일',
                            value: 'sun'
                        }
                    ],
                    mode: 'multiple'
                } })),
            ((_d = values.weekdays) === null || _d === void 0 ? void 0 : _d.length) > 0 && (React.createElement(React.Fragment, null,
                React.createElement(Layout.FluidInline, { justify: "end", stretch: 0 },
                    React.createElement(Button.Group, null,
                        React.createElement(Button, { onClick: function () { return form.setValue('hourBit', ALL_ENABLED); } }, "\uC804\uCCB4\uAC00\uB2A5"),
                        React.createElement(Button, { onClick: function () { return form.setValue('hourBit', ALL_DISABLED); } }, "\uC804\uCCB4\uB9C8\uAC10"))),
                times(isHour ? 24 : 48, function (index) {
                    return (React.createElement(Layout.FluidInline, { justify: "end", gutter: 16 },
                        React.createElement(TextComponent, { children: dayjs()
                                .startOf('day')
                                .add((isHour ? 60 : 30) * index, 'minute')
                                .format('HH:mm') }),
                        React.createElement(HookFormInputs.SwitchInput, { controlProps: { control: form.control, name: "hourBit.".concat(index) }, inputProps: {
                                onChange: function (e) { return set(values, "hourBit.".concat(index), e.target.checked); },
                                checked: get(values, "hourBit.".concat(index))
                            } })));
                }))),
            ((_e = props.schedule) === null || _e === void 0 ? void 0 : _e.scheduleId) && (React.createElement(Button, { danger: true, onClick: handleDelete }, "\uC784\uC2DC \uC6B4\uC601 \uC0AD\uC81C"))) }));
};
var NaverHolidayDisplay = function (props) {
    var _a;
    var publicHoliday = props.holidaySchedules.filter(function (schedule) { return schedule.holidayType === 'PUBLIC'; });
    var regularHoliday = props.holidaySchedules.filter(function (schedule) { return schedule.holidayType === 'REGULAR'; });
    var customHoliday = props.holidaySchedules.filter(function (schedule) { return schedule.holidayType === 'CUSTOM'; });
    var convertRegularToString = function (holidays) {
        // REGULAR는 하나밖에 없음
        var holiday = holidays && holidays[0];
        if (holiday) {
            var weekdays = holiday.weekdays, repetitionType = holiday.repetitionType, weekNumbers = holiday.weekNumbers;
            return "".concat(repetitionType === 'WEEKLY' ? '매주' : repetitionType === 'BI_WEEKLY' ? '격주' : "\uB9E4\uC6D4 ".concat(weekNumbers.join('')), " ").concat(weekdays
                .map(function (day) {
                var a = {
                    SUN: '일요일',
                    MON: '월요일',
                    TUE: '화요일',
                    WED: '수요일',
                    THU: '목요일',
                    FRI: '금요일',
                    SAT: '토요일'
                };
                return a[day];
            })
                .join(','));
        }
        else {
            return '정기 휴무일 없음';
        }
    };
    var convertPublicToString = function (holiday) {
        var items = [];
        holiday.map(function (holiday) {
            var _a, _b, _c, _d;
            if (((_a = holiday.holidayName) === null || _a === void 0 ? void 0 : _a.includes('추석')) || ((_b = holiday.holidayName) === null || _b === void 0 ? void 0 : _b.includes('설날'))) {
                var label_1 = ((_c = holiday.holidayName) === null || _c === void 0 ? void 0 : _c.includes('추석')) ? '추석' : '설날';
                items = items.concat((_d = holiday.extraHolidays) === null || _d === void 0 ? void 0 : _d.map(function (extraHoliday) {
                    if (extraHoliday === 0) {
                        return label_1;
                    }
                    else if (extraHoliday === -1) {
                        return "".concat(label_1, " \uC804\uB0A0");
                    }
                    else if (extraHoliday === 1) {
                        return "".concat(label_1, " \uB2E4\uC74C\uB0A0");
                    }
                }));
            }
            else {
                items = items.concat(holiday.holidayName);
            }
        });
        return items.join(' , ');
    };
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    return (React.createElement(Card, { title: "\uD734\uBB34\uC77C", bordered: false, size: "small", extra: React.createElement(ButtonWithPlusCircle, { children: "", onClick: function () { return setOpen(true); } }) },
        ((_a = props.holidaySchedules) === null || _a === void 0 ? void 0 : _a.length) === 0 ? (React.createElement(Empty, { description: "\uD734\uBB34\uC77C\uC774 \uC5C6\uC2B5\uB2C8\uB2E4." })) : (React.createElement(Layout.Stack, { gutter: 16, style: { padding: '20px', width: '100%' } },
            React.createElement(Layout.Stack, { gutter: 8 },
                React.createElement(TextComponent, { children: "\uC815\uAE30 \uD734\uBB34\uC77C", color: COLORS.gray3 }),
                React.createElement(TextComponent, { children: convertRegularToString(regularHoliday), marginLeft: 5, type: "headline3" })),
            React.createElement(Layout.Stack, { gutter: 8 },
                React.createElement(TextComponent, { children: "\uBC95\uC815 \uACF5\uD734\uC77C", color: COLORS.gray3 }),
                React.createElement(TextComponent, { children: convertPublicToString(publicHoliday), type: "headline3" })))),
        React.createElement(NaverHolidayEditor, __assign({}, props, { open: open, onClose: function () { return setOpen(false); } }))));
};
var NaverHolidayEditor = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var form = useForm();
    var values = form.watch();
    var DAY_OPTIONS = [
        {
            label: '월',
            value: 'MON'
        },
        {
            label: '화',
            value: 'TUE'
        },
        {
            label: '수',
            value: 'WED'
        },
        {
            label: '목',
            value: 'THU'
        },
        {
            label: '금',
            value: 'FRI'
        },
        {
            label: '토',
            value: 'SAT'
        },
        {
            label: '일',
            value: 'SUN'
        }
    ];
    var publicHoliday = props.holidaySchedules.filter(function (schedule) { return schedule.holidayType === 'PUBLIC'; });
    var regularHoliday = props.holidaySchedules.filter(function (schedule) { return schedule.holidayType === 'REGULAR'; });
    // const customHoliday = props.holidaySchedules.filter((schedule) => schedule.holidayType === 'CUSTOM');
    var getDefaultValues = function () {
        var values = {};
        if (!isEmpty(regularHoliday)) {
            regularHoliday[0];
            values['regular'] = {
                repetitionType: regularHoliday[0].repetitionType,
                startDate: regularHoliday[0].startDate,
                weekNumbers: regularHoliday[0].weekNumbers || [],
                weekdays: regularHoliday[0].weekdays || []
            };
            values['hasHoliday'] = true;
        }
        if (!isEmpty(publicHoliday)) {
            values['public'] = publicHoliday || [];
        }
        return __assign({}, values);
    };
    React.useEffect(function () {
        if (!props.open) {
            return form.reset({});
        }
        form.reset(getDefaultValues());
    }, [props.holidaySchedules, props.open]);
    var mutation = NaverLambdaQuery.useCreateAndDeleteNaverHoliday();
    var handleOK = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, deletedIds, holidaySchedules, err_6;
        var _a, _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    if (((_b = (_a = values.regular) === null || _a === void 0 ? void 0 : _a.weekdays) === null || _b === void 0 ? void 0 : _b.length) > 0 && !((_c = values === null || values === void 0 ? void 0 : values.regular) === null || _c === void 0 ? void 0 : _c.repetitionType)) {
                        return [2 /*return*/, notification.error({ message: '정기 휴무일의 간격을 선택해주세요.' })];
                    }
                    _h.label = 1;
                case 1:
                    _h.trys.push([1, 3, 4, 5]);
                    payload = void 0;
                    if (!values.hasHoliday) {
                        payload = {
                            businessId: props.businessId,
                            bizItemId: props.bizItemId,
                            holidaySchedules: [],
                            storeId: props.store.id,
                            deletedIds: (_d = (publicHoliday || [])) === null || _d === void 0 ? void 0 : _d.map(function (item) { return item.holidayScheduleId; }).concat(regularHoliday === null || regularHoliday === void 0 ? void 0 : regularHoliday.map(function (item) { return item.holidayScheduleId; })).filter(Boolean)
                        };
                    }
                    else {
                        deletedIds = [];
                        holidaySchedules = [];
                        if (isChanged().regular) {
                            if (regularHoliday[0]) {
                                deletedIds.push(regularHoliday[0].holidayScheduleId);
                            }
                            if (!isEmpty(((_e = values.regular) === null || _e === void 0 ? void 0 : _e.weekdays) || []) && ((_f = values.regular) === null || _f === void 0 ? void 0 : _f.repetitionType)) {
                                holidaySchedules.push(__assign(__assign({}, values.regular), { holidayType: 'REGULAR' }));
                            }
                        }
                        if (isChanged().publicHolidayChanged) {
                            deletedIds = deletedIds.concat((_g = publicHoliday === null || publicHoliday === void 0 ? void 0 : publicHoliday.map(function (item) { return item.holidayScheduleId; })) === null || _g === void 0 ? void 0 : _g.filter(Boolean));
                            holidaySchedules = holidaySchedules.concat(values.public);
                        }
                        payload = {
                            businessId: props.businessId,
                            bizItemId: props.bizItemId,
                            holidaySchedules: holidaySchedules.filter(Boolean),
                            storeId: props.store.id,
                            deletedIds: deletedIds
                        };
                    }
                    return [4 /*yield*/, mutation.mutateAsync(payload)];
                case 2:
                    _h.sent();
                    notification.success({ message: "\uD734\uBB34 \uC218\uC815\uC774 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4." });
                    return [3 /*break*/, 5];
                case 3:
                    err_6 = _h.sent();
                    notification.success({ message: err_6.message || "\uD734\uBB34 \uC218\uC815 \uC911 \uC624\uB958 \uBC1C\uC0DD" });
                    return [3 /*break*/, 5];
                case 4:
                    props.onClose();
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var weekDayOptions = [
        { label: '첫째', value: 1 },
        { label: '둘째', value: 2 },
        { label: '셋째', value: 3 },
        { label: '넷째', value: 4 },
        { label: '다섯째', value: 5 }
    ];
    var _j = React.useState(), modalType = _j[0], setModalType = _j[1];
    var _k = React.useState([]), weekNumbers = _k[0], setWeekNumbers = _k[1];
    var _l = React.useState(), startDate = _l[0], setStartDate = _l[1];
    var regularWeekdays = ((_a = values === null || values === void 0 ? void 0 : values.regular) === null || _a === void 0 ? void 0 : _a.weekdays) || [];
    var isChanged = function () {
        var _a, _b;
        var publicHolidayChanged = ((_a = values.public) === null || _a === void 0 ? void 0 : _a.length) !== (publicHoliday === null || publicHoliday === void 0 ? void 0 : publicHoliday.length) ||
            !!((_b = values.public) === null || _b === void 0 ? void 0 : _b.find(function (item) {
                if (!item.holidayScheduleId) {
                    return true;
                }
                else {
                    var sameThing = publicHoliday.find(function (schedule) { return schedule.holidayScheduleId === item.holidayScheduleId; });
                    return !isEqual(sameThing, item);
                }
            }));
        var hasHoliday = form.getFieldState('hasHoliday').isDirty;
        var regular = form.getFieldState('regular').isDirty;
        return {
            form: hasHoliday || regular || publicHolidayChanged,
            publicHolidayChanged: publicHolidayChanged,
            hasHoliday: hasHoliday,
            regular: regular
        };
    };
    return (React.createElement(BigTitleModal, { open: !!props.open, centered: true, onOk: form.handleSubmit(handleOK), okButtonProps: {
            disabled: !isChanged().form
        }, okText: "\uC800\uC7A5", onCancel: props.onClose, title: "\uD734\uBB34 \uC218\uC815", children: React.createElement(Layout.Stack, { gutter: 8, style: { padding: '20px', width: '100%' } },
            React.createElement(Button.Group, { style: { width: '100%', marginBottom: '10px' } },
                React.createElement(Button, { children: "\uD734\uBB34 \uC788\uC74C", size: "large", style: { width: '100%' }, onClick: function () { return form.setValue('hasHoliday', true, { shouldDirty: true }); }, type: values.hasHoliday ? 'primary' : 'default' }),
                React.createElement(Button, { children: "\uD734\uBB34 \uC5C6\uC74C", size: "large", style: { width: '100%' }, onClick: function () { return form.setValue('hasHoliday', false, { shouldDirty: true }); }, type: !values.hasHoliday ? 'primary' : 'default' })),
            values.hasHoliday ? (React.createElement(React.Fragment, null,
                React.createElement(Layout.Stack, { gutter: 8 },
                    React.createElement(TextComponent, { children: "\uC815\uAE30 \uD734\uBB34\uC77C(\uC26C\uB294\uC694\uC77C \uC120\uD0DD)", color: COLORS.gray3 }),
                    React.createElement(Button.Group, null,
                        React.createElement(Button, { children: '매주', type: ((_b = values === null || values === void 0 ? void 0 : values.regular) === null || _b === void 0 ? void 0 : _b.repetitionType) === 'WEEKLY' ? 'primary' : 'default', onClick: function () { return form.setValue('regular.repetitionType', 'WEEKLY', { shouldDirty: true }); } }),
                        React.createElement(Button, { type: ((_c = values === null || values === void 0 ? void 0 : values.regular) === null || _c === void 0 ? void 0 : _c.repetitionType) === 'BI_WEEKLY' ? 'primary' : 'default', children: '격주', onClick: function () {
                                setModalType('BI_WEEKLY');
                            } }),
                        React.createElement(Button, { children: "\uB9E4\uB2EC".concat(((_e = (_d = values === null || values === void 0 ? void 0 : values.regular) === null || _d === void 0 ? void 0 : _d.weekNumbers) === null || _e === void 0 ? void 0 : _e.join(',')) ? " ".concat((_g = (_f = values === null || values === void 0 ? void 0 : values.regular) === null || _f === void 0 ? void 0 : _f.weekNumbers) === null || _g === void 0 ? void 0 : _g.join(','), "\uBC88\uC9F8") : ''), type: ((_h = values === null || values === void 0 ? void 0 : values.regular) === null || _h === void 0 ? void 0 : _h.repetitionType) === 'MONTHLY' ? 'primary' : 'default', onClick: function () {
                                setModalType('MONTHLY');
                            } })),
                    React.createElement(Button.Group, null, DAY_OPTIONS.map(function (item) { return (React.createElement(Button, { key: item.value, children: item.label, type: regularWeekdays.includes(item.value) ? 'primary' : 'default', onClick: function () {
                            form.setValue('regular.weekdays', (regularWeekdays === null || regularWeekdays === void 0 ? void 0 : regularWeekdays.includes(item.value))
                                ? regularWeekdays === null || regularWeekdays === void 0 ? void 0 : regularWeekdays.filter(function (day) { return day !== item.value; })
                                : __spreadArray(__spreadArray([], regularWeekdays, true), [item.value], false), { shouldDirty: true });
                        } })); })),
                    React.createElement(TextComponent, { children: "\uACF5\uD734\uC77C \uC124\uC815", color: COLORS.gray3, marginTop: 20 }),
                    React.createElement(Row, { gutter: 8 }, NaverLambdaQuery.PUBLIC_HOLIDAY_FROM_NAVER.map(function (item) {
                        var _a, _b, _c;
                        if (item.extraHolidays) {
                            var name_1 = (_a = item.holidayName) === null || _a === void 0 ? void 0 : _a.split(' ')[0];
                            var selected_1 = (_b = values.public) === null || _b === void 0 ? void 0 : _b.find(function (holiday) { return name_1 === holiday.holidayName.split(' ')[0]; });
                            return (React.createElement(Col, { style: { marginBottom: '5px' } },
                                React.createElement(Button.Group, null, item.extraHolidays.map(function (day) {
                                    var _a;
                                    var selectedExtra = (_a = selected_1 === null || selected_1 === void 0 ? void 0 : selected_1.extraHolidays) === null || _a === void 0 ? void 0 : _a.includes(day);
                                    return (React.createElement(Button, { onClick: function () {
                                            var _a;
                                            var publicWithoutSelected = ((_a = values.public) === null || _a === void 0 ? void 0 : _a.filter(function (holiday) { return name_1 !== holiday.holidayName.split(' ')[0]; })) ||
                                                [];
                                            var extraHoliday = addOrRemove((selected_1 === null || selected_1 === void 0 ? void 0 : selected_1.extraHolidays) || [], day);
                                            if (isEmpty(extraHoliday)) {
                                                form.setValue('public', publicWithoutSelected, { shouldDirty: true });
                                            }
                                            else {
                                                var newValue = selected_1 || __assign({}, item);
                                                newValue.extraHolidays = extraHoliday;
                                                form.setValue('public', publicWithoutSelected.concat(newValue), {
                                                    shouldDirty: true
                                                });
                                            }
                                        }, type: selected_1 && selectedExtra ? 'primary' : 'default' }, "".concat(name_1, " ").concat(day === -1 ? '전날' : day === 1 ? '다음날' : '당일')));
                                }))));
                        }
                        else {
                            var selected = (_c = values.public) === null || _c === void 0 ? void 0 : _c.find(function (holiday) { return item.holidayName === holiday.holidayName; });
                            return (React.createElement(Col, { style: { marginBottom: '5px' } },
                                React.createElement(Button, { onClick: function () {
                                        form.setValue('public', addOrRemove(values.public || [], item, function (holiday, target) { return target.holidayName === holiday.holidayName; }), { shouldDirty: true });
                                    }, children: item.holidayName, key: item.holidayName, type: selected ? 'primary' : 'default' })));
                        }
                    }))))) : (React.createElement(Empty, { description: "\uD734\uBB34\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4." })),
            React.createElement(Modal, { centered: true, zIndex: 10002, open: !!modalType, title: modalType === 'MONTHLY' ? '정기 휴무 패턴을 선택해주세요' : '적용 시작일을 선택해주세요.', onCancel: function () {
                    setModalType(null);
                }, style: { padding: 0 }, onOk: function () {
                    if (modalType === 'BI_WEEKLY') {
                        form.setValue('regular', __assign(__assign({}, values.regular), { repetitionType: 'BI_WEEKLY', startDate: dayjs(startDate).format('YYYY-MM-DD') }), { shouldDirty: true });
                    }
                    else {
                        form.setValue('regular', __assign(__assign({}, values.regular), { repetitionType: 'MONTHLY', weekNumbers: weekNumbers }), { shouldDirty: true });
                    }
                    setModalType(null);
                }, children: modalType === 'MONTHLY' ? (React.createElement(React.Fragment, null,
                    React.createElement(TextComponent, { type: "headline3", children: "\uB9E4\uB2EC" }),
                    React.createElement(Button.Group, null, weekDayOptions.map(function (item) {
                        return (React.createElement(Button, { type: weekNumbers.includes(item.value) ? 'primary' : 'default', onClick: function () {
                                if (weekNumbers.includes(item.value)) {
                                    setWeekNumbers(weekNumbers.filter(function (weekNumber) { return weekNumber !== item.value; }));
                                }
                                else {
                                    setWeekNumbers(weekNumbers.concat(item.value));
                                }
                            } }, item.label));
                    })))) : (React.createElement(Calendar, { mode: "month", fullscreen: false, disabledDate: function (date) { return dayjs(date).isBefore(dayjs(), 'day'); }, onChange: function (date) {
                        setStartDate(date.toDate());
                    } })) })) }));
};
var FormContainer = function (props) {
    return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
        React.createElement("form", { style: { flex: 1, paddingBottom: '70px' } }, props.formElement),
        React.createElement(FloatingButtonGroup, { visible: props.visible, confirmButtonProps: {
                onClick: props.onConfirm
            }, cancelButtonProps: {
                onClick: props.onCancel
            } })));
};
var FluidSelect = styled(HookFormInputs.SelectInput)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 200px;\n  margin: 10px;\n"], ["\n  width: 200px;\n  margin: 10px;\n"])));
var Tab = function (props) {
    return (React.createElement(TabWrapper, null,
        React.createElement(TextComponent, { children: props.label })));
};
var Form = styled.form(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  & > * {\n    margin-bottom: 15px;\n  }\n"], ["\n  & > * {\n    margin-bottom: 15px;\n  }\n"])));
var TabWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 5px;\n"], ["\n  padding: 5px;\n"])));
var TabContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  max-width: 1080px;\n  margin: 0 auto;\n  height: 100%;\n"], ["\n  max-width: 1080px;\n  margin: 0 auto;\n  height: 100%;\n"])));
function formatOperatingHours(hoursString) {
    var interval = hoursString.length <= 24 ? 60 : 30;
    var startString;
    var endString;
    var items = [];
    Array.from(hoursString).forEach(function (item, index) {
        if (item === '1') {
            if (!startString) {
                startString = dayjs()
                    .startOf('day')
                    .add(interval * index, 'minute')
                    .format('HH:mm');
            }
        }
        else if (item === '0') {
            if (!endString && startString) {
                endString = dayjs()
                    .startOf('day')
                    .add(interval * (index - 1), 'minute')
                    .format('HH:mm');
                items.push({ startString: startString, endString: endString });
                startString = null;
                endString = null;
            }
        }
        if (hoursString.length - 1 === index && startString) {
            items.push({ startString: startString, endString: interval === 60 ? '23:00' : '23:30' });
        }
    });
    return items.map(function (item) { return "".concat(item.startString, " ~ ").concat(item.endString); });
}
function daysOfWeek(arr) {
    var weekdays = {
        mon: '월',
        tue: '화',
        wed: '수',
        thu: '목',
        fri: '금',
        sat: '토',
        sun: '일'
    };
    var result = [];
    var temp = [];
    for (var i = 0; i < arr.length; i++) {
        var day = arr[i];
        var lastDay = arr[i - 1];
        if (i === arr.length - 1) {
            temp.push(weekdays[day]);
            result.push(temp.join(''));
        }
        else if (day === 'fri' && lastDay !== 'thu') {
            temp.push(weekdays[day]);
            result.push(temp.join(''));
            temp = [];
        }
        else if (lastDay === undefined || weekdays[lastDay] !== weekdays[day] - 1) {
            temp.push(weekdays[day]);
        }
        else if (weekdays[lastDay] === weekdays[day] - 1 && temp.length === 1) {
            temp.push(weekdays[day]);
        }
        else {
            temp.push('~' + weekdays[day]);
            result.push(temp.join(''));
            temp = [weekdays[day]];
        }
    }
    return result.join(',');
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
