var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'renderer/styles';
import Button from 'antd/lib/button';
import { Icon, TextComponent } from 'renderer/components';
export var FloatingPopup = function (_a) {
    var imageUrl = _a.imageUrl, title = _a.title, description = _a.description, buttonText = _a.buttonText, onClose = _a.onClose, onButtonClick = _a.onButtonClick;
    return (React.createElement(PopupWrapper, null,
        React.createElement(StyledCard, null,
            React.createElement(ImageContainer, null,
                React.createElement(PopupImage, { src: imageUrl, alt: "Popup image" }),
                React.createElement(CloseButton, { onClick: onClose, name: "name", size: 24 })),
            React.createElement(ContentContainer, null,
                React.createElement(TextComponent, { type: "subtitle1" }, title),
                React.createElement(TextComponent, { type: "body2", marginBottom: 8, marginTop: 4 }, description),
                React.createElement(StyledButton, { type: "primary", onClick: onButtonClick }, buttonText)))));
};
var PopupWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  z-index: 1000;\n"], ["\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  z-index: 1000;\n"])));
var StyledCard = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 340px;\n  border-radius: 8px;\n  padding: 0;\n  overflow: hidden;\n\n  border-radius: 8px;\n  border: 1px solid ", ";\n  background: ", ";\n\n  /* BoxShadowSecondary */\n  box-shadow:\n    0px 9px 28px 8px rgba(0, 0, 0, 0.05),\n    0px 3px 6px -4px rgba(0, 0, 0, 0.12),\n    0px 6px 16px 0px rgba(0, 0, 0, 0.08);\n"], ["\n  width: 340px;\n  border-radius: 8px;\n  padding: 0;\n  overflow: hidden;\n\n  border-radius: 8px;\n  border: 1px solid ", ";\n  background: ", ";\n\n  /* BoxShadowSecondary */\n  box-shadow:\n    0px 9px 28px 8px rgba(0, 0, 0, 0.05),\n    0px 3px 6px -4px rgba(0, 0, 0, 0.12),\n    0px 6px 16px 0px rgba(0, 0, 0, 0.08);\n"])), COLORS.white, COLORS.white);
var ImageContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n"], ["\n  position: relative;\n  width: 100%;\n"])));
var PopupImage = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n"], ["\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n"])));
var CloseButton = styled(Icon)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  cursor: pointer;\n  font-size: 20px;\n  border-radius: 50%;\n"], ["\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  cursor: pointer;\n  font-size: 20px;\n  border-radius: 50%;\n"])));
var ContentContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 24px;\n"], ["\n  padding: 24px;\n"])));
var StyledButton = styled(Button)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
