var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ManagerQuery, StorePaymentMethodQuery } from 'renderer/queries';
import { COLORS } from 'renderer/styles';
import styled from 'styled-components';
import { TextComponent, HookFormInputs, useEntireLoader, ScrollBar, AntButton } from 'renderer/components';
import { API_STAGE, PATH_APP_STORE_SUBSCRIPTIONS } from 'renderer/constants';
import { BrowserDetect, goToMarket, isPlussharpMobileApp, useWindowSize } from 'renderer/utils';
import { IMAGES } from 'renderer/assets';
import { useForm } from 'react-hook-form';
import { Button, Layout } from 'common-ui';
import { notification } from 'antd';
import { StoreSubscriptionPopup } from 'renderer/containers/app/NewStore/StoreSubscription';
export var PremiumCover = function (props) {
    var _a;
    var navigate = useNavigate();
    var preventAccess = !((_a = props.store) === null || _a === void 0 ? void 0 : _a.hasSubscription) || props.preventAccess;
    var storeId = props.store.id;
    var availableTrialQuery = StorePaymentMethodQuery.useAvailableTrial({ storeId: storeId }, { enabled: !!storeId });
    var textType = props.textType || 'headline2';
    var availableTrial = availableTrialQuery.data;
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, navigate(PATH_APP_STORE_SUBSCRIPTIONS)];
        });
    }); };
    return preventAccess ? (React.createElement(EntireBlur, { "$isMobilePreventer": !!props.isMobilePreventer },
        props.isThreeLine ? (React.createElement(React.Fragment, null,
            React.createElement(TextComponent, { color: COLORS.black, type: textType, children: "\uC774 \uAE30\uB2A5\uC740", marginBottom: 10 }),
            React.createElement(TextComponent, { color: COLORS.black, type: textType, children: "\uC720\uB8CC \uD50C\uB79C \uC774\uC6A9 \uC2DC \uC81C\uACF5\uB418\uB294 \uAE30\uB2A5\uC785\uB2C8\uB2E4.", marginBottom: 10 }))) : (React.createElement(TextComponent, { color: COLORS.black, type: textType, children: "\uC774 \uAE30\uB2A5\uC740 \uC720\uB8CC \uD50C\uB79C \uC774\uC6A9 \uC2DC \uC81C\uACF5\uB418\uB294 \uAE30\uB2A5\uC785\uB2C8\uB2E4.", marginBottom: 10 })),
        availableTrial ? (React.createElement(React.Fragment, null,
            React.createElement(TextComponent, { color: COLORS.black, type: textType, children: "\uBB34\uB8CC\uCCB4\uD5D8 \uC911\uC5D0\uB294 \uACB0\uC81C\uAC00 \uB418\uC9C0 \uC54A\uC73C\uB2C8", marginBottom: 10 }),
            React.createElement(TextComponent, { color: COLORS.black, type: textType, children: "\uC548\uC2EC\uD558\uACE0 \uCCB4\uD5D8 \uC2DC\uC791\uD558\uC138\uC694!", marginBottom: 10 }))) : (React.createElement(TextComponent, { color: COLORS.black, type: textType, children: '지금 바로 이용해보세요!', marginBottom: 24 })),
        React.createElement(StoreSubscriptionPopup, { trigger: React.createElement(AntButton, { type: "primary", children: !availableTrial ? '유료플랜 이용하기' : '30일 무료 체험하기', onClick: handleClick }) }))) : (React.createElement("div", null));
};
export var PremiumCoverForApplication = function (props) {
    var _a;
    var navigate = useNavigate();
    var preventAccess = !((_a = props.store) === null || _a === void 0 ? void 0 : _a.hasSubscription) || props.preventAccess;
    var storeId = props.store.id;
    var availableTrialQuery = StorePaymentMethodQuery.useAvailableTrial({ storeId: storeId }, { enabled: !!storeId });
    var textType = props.textType || 'headline2';
    var availableTrial = availableTrialQuery.data;
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, navigate(PATH_APP_STORE_SUBSCRIPTIONS)];
        });
    }); };
    // 모바일∙태블릿앱은
    // 유료 플랜 이용 시 제공되는 기능입니다.
    // 무료체험 중에는 결제가 되지 않으니
    // 안심하고 체험 시작하세요!
    return preventAccess ? (React.createElement(EntireBlur, { "$isMobilePreventer": !!props.isMobilePreventer },
        React.createElement(Wrapper, null,
            React.createElement(TextComponent, { color: COLORS.black, type: textType, children: "\uBAA8\uBC14\uC77C\u2219\uD0DC\uBE14\uB9BF\uC571\uC740" }),
            React.createElement(TextComponent, { color: COLORS.black, type: textType, children: "\uC720\uB8CC \uD50C\uB79C \uC774\uC6A9 \uC2DC \uC81C\uACF5\uB418\uB294 \uAE30\uB2A5\uC785\uB2C8\uB2E4." }),
            availableTrial ? (React.createElement(React.Fragment, null,
                React.createElement(TextComponent, { color: COLORS.black, type: textType, children: "\uD305\uCEE4\uBDF0 \uC774\uC6A9\uC774 \uCC98\uC74C\uC774\uB77C\uBA74" }),
                React.createElement(TextComponent, { color: COLORS.black, type: textType, children: "30\uC77C\uB3D9\uC548 \uBB34\uB8CC\uB85C \uCCB4\uD5D8\uD560 \uC218 \uC788\uC5B4\uC694!" }),
                React.createElement(TextComponent, { color: COLORS.black, type: textType, children: "\uBB34\uB8CC\uCCB4\uD5D8 \uAE30\uAC04\uC774 \uC885\uB8CC\uB418\uC5B4\uB3C4" }),
                React.createElement(TextComponent, { color: COLORS.black, type: textType, children: "\uC694\uAE08\uC774 \uACB0\uC81C\uB418\uC9C0 \uC54A\uC73C\uB2C8 \uC548\uC2EC\uD558\uACE0 \uC774\uC6A9\uD558\uC138\uC694", marginBottom: 24 }))) : (React.createElement(TextComponent, { color: COLORS.black, type: textType, children: '지금 바로 이용해보세요!', marginBottom: 20 })),
            React.createElement(StoreSubscriptionPopup, { trigger: React.createElement(AntButton, { type: "primary", children: !availableTrial ? '유료플랜 이용하기' : '30일 무료 체험하기', onClick: handleClick }) })))) : (React.createElement("div", null));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 200px;\n"], ["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 200px;\n"])));
export var PasswordLocker = function (props) {
    var manager = ManagerQuery.getLoginManagerInCache();
    var managerId = manager.id;
    var check = ManagerQuery.useCheckManager();
    var form = useForm({ mode: 'onChange' });
    var loader = useEntireLoader();
    var _a = React.useState(false), isVerified = _a[0], setIsVerified = _a[1];
    var onSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var errors, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    errors = form.formState.errors;
                    if (!!errors.password) return [3 /*break*/, 2];
                    loader.show();
                    return [4 /*yield*/, check.mutateAsync({
                            password: form.getValues('password'),
                            storeId: manager.storeId,
                            id: managerId
                        })];
                case 1:
                    result = _a.sent();
                    if (result.isVerified) {
                        setIsVerified(result.isVerified);
                    }
                    else {
                        notification.warning({ message: '비밀번호를 확인해주세요.', placement: 'topLeft' });
                    }
                    loader.hide();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    return isVerified ? (React.createElement("div", null)) : (React.createElement(PasswordLockerWrapper, __assign({ backgroundColor: COLORS.white }, props),
        React.createElement("form", { onSubmit: form.handleSubmit(onSubmit) },
            React.createElement(TextComponent, { children: props.title || '비밀번호 확인', type: "subtitle1" }),
            React.createElement(TextComponent, { marginTop: 10, marginBottom: 20, children: props.description || '보안을 위해 비밀번호를 다시 한번 확인해주세요.', type: "subtitle3", color: COLORS.gray3 }),
            React.createElement(HookFormInputs.Text, { label: "\uBE44\uBC00\uBC88\uD638", controlProps: {
                    name: 'password',
                    control: form.control,
                    rules: { required: '비밀번호를 입력해주세요.' }
                }, inputProps: {
                    autoFocus: true,
                    type: 'password'
                } }),
            React.createElement(Button.ContainButton, { style: { marginTop: '10px' }, children: "\uD655\uC778" }))));
};
export var PreventMobileWeb = function (props) {
    var store = props.store, rest = __rest(props, ["store"]);
    var location = useLocation();
    return (BrowserDetect.isMobile() &&
        (isPlussharpMobileApp() ? (location.pathname !== PATH_APP_STORE_SUBSCRIPTIONS && (React.createElement(PremiumCoverForApplication, { isThreeLine: true, store: store, isMobilePreventer: true, textType: "headline3" }))) : API_STAGE === 'production' ? (React.createElement(EntireBlur, __assign({}, rest),
            React.createElement("img", { src: IMAGES.app_icon }),
            React.createElement("img", { src: IMAGES.icon_star, style: { marginTop: '10px' } }),
            React.createElement(TextComponent, { color: COLORS.white, children: "\uC774\uC6A9\uC774 \uC27D\uACE0", marginTop: 10 }),
            React.createElement(TextComponent, { color: COLORS.white, children: "\uC5B4\uB514\uC11C\uB4E0 \uAD00\uB9AC\uD560 \uC218 \uC788\uC5B4 \uD3B8\uD574\uC694" }),
            React.createElement(CircularButton, { onClick: function () {
                    goToMarket();
                } },
                React.createElement(TextComponent, { color: COLORS.white, children: "\uD305\uCEE4\uBDF0 \uC571\uC73C\uB85C \uBCF4\uAE30" })))) : (React.createElement("div", null))));
};
var CircularButton = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 206px;\n  height: 32px;\n  margin: 20px 0 0;\n  padding: 6px 16px 6px 43px;\n  border-radius: 16px;\n  background-color: ", ";\n"], ["\n  width: 206px;\n  height: 32px;\n  margin: 20px 0 0;\n  padding: 6px 16px 6px 43px;\n  border-radius: 16px;\n  background-color: ", ";\n"])), COLORS.primary1);
export var EntireBlur = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  z-index: ", ";\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  top: ", ";\n  left: 0;\n"], ["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  z-index: ", ";\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  top: ", ";\n  left: 0;\n"])), function (props) { return (props.$isMobilePreventer ? 1000 : 9999); }, function (props) { return props.backgroundColor || 'rgba(246, 246, 247, 0.8)'; }, function (props) { return (props.$isMobilePreventer ? '60px' : '0'); });
var PasswordLockerWrapper = styled(EntireBlur)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-start;\n  flex-direction: column;\n  justify-content: flex-start;\n"], ["\n  display: flex;\n  align-items: flex-start;\n  flex-direction: column;\n  justify-content: flex-start;\n"])));
export var ManagerPermissionContent = function (props) {
    var _a, _b;
    var isPrevent = typeof props.isPrevent === 'boolean' ? props.isPrevent : !((_a = props.manager) === null || _a === void 0 ? void 0 : _a.hasPermission(props.permission));
    return (React.createElement(ManagerPermissionContentWrapper, null,
        isPrevent && (React.createElement(BlurComponent, { children: React.createElement(React.Fragment, null,
                React.createElement("img", { src: IMAGES.icon_warning }), (_b = props.descriptions) === null || _b === void 0 ? void 0 :
                _b.map(function (item) {
                    return (React.createElement(TextComponent, { color: COLORS.black, type: "subtitle2", key: item, children: item, marginBottom: 10 }));
                }),
                React.createElement(TextComponent, { color: COLORS.black, type: "subtitle2", children: "\uAD00\uB9AC\uC790\uACC4\uC815\uC744 \uD1B5\uD574 \uAD8C\uD55C\uC744 \uD5C8\uC6A9\uD574\uC8FC\uC138\uC694.", marginBottom: 10 })) })),
        props.children));
};
var ManagerPermissionContentWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n"], ["\n  width: 100%;\n  height: 100%;\n"])));
export var BlurComponent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  z-index: 3;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  top: 0;\n  padding: 15px;\n  left: 0;\n"], ["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  z-index: 3;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  top: 0;\n  padding: 15px;\n  left: 0;\n"])), COLORS.white);
export var AdditionalCover = function (props) {
    var _a, _b, _c;
    var navigate = useNavigate();
    var preventAccess = ['autoMessage', 'employee'].includes(props.type)
        ? !((_a = props.store) === null || _a === void 0 ? void 0 : _a.hasSubscription)
        : !((_b = props.store) === null || _b === void 0 ? void 0 : _b.hasSubplan(props.type));
    var storeId = props.store.id;
    var availableTrialQuery = StorePaymentMethodQuery.useAvailableTrial({ storeId: storeId }, { enabled: !!storeId });
    var availableTrial = availableTrialQuery.data;
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, navigate(PATH_APP_STORE_SUBSCRIPTIONS)];
        });
    }); };
    var isPC = useWindowSize().isPC;
    var dataObject = {
        autoMessage: {
            title: '문자 자동발송 설정',
            headers: ['문자발송,', '자동으로 더 간편하게'],
            descriptions: ['한 번만 설정해두면 예약한 시간에 자동으로 발송완료!', '매번 신경 쓸 필요가 없어 편리해요.'],
            images: [IMAGES.additional_image_1, IMAGES.additional_image_2]
        },
        employee: {
            title: '직원 추가 및 관리',
            headers: ['직원이 많더라도', '부담없이 이용하세요'],
            descriptions: ['직원 수가 많아져도 금액은 9,900원으로 동일하니까!', '비용부담없이 직원을 추가해보세요!'],
            images: [IMAGES.additional_image_3, IMAGES.additional_image_4]
        },
        customFields: {
            title: '고객 커스텀필드',
            headers: ['입력하고 싶은 고객정보를', '우리상점에 꼭 맞게'],
            descriptions: [
                '추가 연락처,  회원번호 등',
                '우리상점에 꼭 필요한 고객정보만 입력하고 싶다면?',
                '이제 고객필드를 자유롭게 커스텀해보세요!'
            ],
            images: [IMAGES.additional_image_5, IMAGES.additional_image_6]
        },
        multilink: {
            title: '팅커뷰링크',
            headers: ['상점의 모든 채널을', '단 하나의 링크로'],
            descriptions: [
                '보여주고 싶은 채널은 많은데',
                '연결할 수 있는 프로필 링크는 1개라면?🥲',
                '팅커뷰링크 하나로 다 모아서 보여주세요! '
            ],
            images: [IMAGES.additional_image_7, IMAGES.additional_image_8],
            imageWidth: '260px'
        },
        deposit: {
            title: '예약금입금 자동확인 서비스',
            headers: ['예약금 입금, ', '일일이 확인할 필요 없이', '자동으로 간편하게'],
            descriptions: [
                '입금확인 처리시간을 단축해 업무효율을 높여보세요!',
                '운영 외 시간에도 입금이 자동으로 확인됩니다🙂'
            ],
            images: [IMAGES.additional_image_9, IMAGES.additional_image_10],
            imageWidth: '260px'
        },
        fts: {
            title: '카카오톡 발송',
            headers: ['문자보다 더 저렴한 카카오톡 발송'],
            descriptions: ['카카오 친구톡/알림톡으로 발송하면', '최대 23% 문자비용 감소!'],
            images: [IMAGES.additional_image_11]
        }
    };
    var data = dataObject[props.type];
    return preventAccess && data ? (React.createElement(StyledWrapper, null,
        React.createElement(ScrollBar, { style: { height: '100%', width: '100%' } },
            React.createElement(ContentWrapper, null,
                React.createElement(TextComponent, { color: COLORS.primary1, type: "headline3", children: data.title, marginBottom: 10 }),
                data.headers.map(function (item, index) {
                    return (React.createElement(TextComponent, { key: "header_".concat(index), type: "headline1", children: item, marginTop: index === 0 ? 10 : 0 }));
                }),
                data.descriptions.map(function (item, index) {
                    return (React.createElement(TextComponent, { key: "descriptions_".concat(index), type: "body2", children: item, marginTop: index === 0 ? 10 : 0 }));
                }),
                React.createElement(StoreSubscriptionPopup, { trigger: React.createElement(Button.PremiumButton, { children: props.type === 'deposit' ? '이용 시작하기' : '30일 무료체험하기', style: { marginBottom: '30px', marginTop: '30px' }, onClick: handleClick }) }),
                React.createElement(Layout.Columns, { columns: isPC && !props.isNarrow ? (_c = data.images) === null || _c === void 0 ? void 0 : _c.length : 1, gutter: 8 }, data.images.map(function (item, index) {
                    return (React.createElement(Layout.Column, null,
                        React.createElement("img", { src: item, style: { maxWidth: '100%', width: data.imageWidth || '340px' } })));
                })))))) : (React.createElement("div", null));
};
var ContentWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding-top: 20px;\n  padding-bottom: 60px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding-top: 20px;\n  padding-bottom: 60px;\n"])));
var StyledWrapper = styled(EntireBlur)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background-color: white;\n  display: flex;\n  z-index: 1;\n"], ["\n  background-color: white;\n  display: flex;\n  z-index: 1;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
