var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import Tag from 'antd/lib/tag';
import { COLORS } from 'renderer/styles';
import { convertHexToRgbA } from 'renderer/utils';
import styled from 'styled-components';
import { Row } from 'antd/lib/grid';
import { Layout } from 'common-ui';
export var CustomerSegmentContainer = function (props) {
    return (React.createElement(Row, { wrap: props.wrap === undefined ? true : props.wrap, style: props.style }, props.items.map(function (item, index) {
        var name = item.name || '';
        var text = name;
        return (React.createElement(ColorTagWithWhiteFont, { key: index, children: text || '', color: item.color || COLORS.segment1, style: { marginBottom: '4px' } }));
    })));
};
export var CustomerSegmentContainerInScheduleBlock = function (props) {
    return (React.createElement(Layout.Inline, { gutter: '2px' }, props.items.map(function (item, index) {
        var name = item.name || '';
        var nameLength = name.length;
        var text = name;
        return (React.createElement(SegmentTagInScehduleBlock, { key: index, children: text || '', color: item.color || COLORS.segment1, style: { overflow: 'hidden' } }));
    })));
};
export var AntTag = Tag;
export var ColorTag = styled(Tag).attrs({ closable: false })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", " !important;\n  background-color: ", " !important;\n  font-family: Noto Sans KR;\n  font-size: 13px;\n  font-weight: 500;\n"], ["\n  color: ", " !important;\n  background-color: ", " !important;\n  font-family: Noto Sans KR;\n  font-size: 13px;\n  font-weight: 500;\n"])), function (props) { return props.color; }, function (props) { return convertHexToRgbA(props.color, 0.2); });
export var ColorTagWithWhiteFont = styled(Tag)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", " !important;\n  background-color: ", " !important;\n  font-family: Noto Sans KR;\n  font-size: 13px;\n  font-weight: 500;\n  margin-right: 4px;\n"], ["\n  color: ", " !important;\n  background-color: ", " !important;\n  font-family: Noto Sans KR;\n  font-size: 13px;\n  font-weight: 500;\n  margin-right: 4px;\n"])), COLORS.black, function (props) { return props.color; });
export var SegmentTagInScehduleBlock = styled(ColorTagWithWhiteFont)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", " !important;\n  background-color: ", " !important;\n  font-family: Noto Sans KR;\n  font-size: 12px;\n  font-weight: 500;\n  padding-left: 2px;\n  padding-right: 2px;\n  min-width: 8px;\n  margin-bottom: 1px;\n  line-height: 13px;\n  height: 16px;\n"], ["\n  color: ", " !important;\n  background-color: ", " !important;\n  font-family: Noto Sans KR;\n  font-size: 12px;\n  font-weight: 500;\n  padding-left: 2px;\n  padding-right: 2px;\n  min-width: 8px;\n  margin-bottom: 1px;\n  line-height: 13px;\n  height: 16px;\n"])), COLORS.black, function (props) { return props.color; });
export var DoubleLineStoreOffTag = styled(ColorTag)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 12;\n  top: ", "px;\n  left: 8px;\n"], ["\n  position: absolute;\n  z-index: 12;\n  top: ", "px;\n  left: 8px;\n"])), function (props) { return props.$headerHeight + 10; });
export var ScheduleStatusTag = function (props) {
    var _a, _b;
    var statusColor = {
        pending: COLORS.schedule1,
        confirm: COLORS.schedule2,
        completed: COLORS.schedule2,
        sales: COLORS.schedule3,
        noshow: COLORS.schedule4,
        cancelled_customer: COLORS.schedule5,
        cancelled_store: COLORS.schedule5
    };
    var status = ((_a = props.schedule) === null || _a === void 0 ? void 0 : _a.salesHistoryId) ? 'sales' : (_b = props.schedule) === null || _b === void 0 ? void 0 : _b.status;
    var color = statusColor[status];
    var childrens = {
        pending: '예약대기',
        confirm: '예약확정',
        sales: '매출등록',
        completed: '이용완료',
        noshow: '노쇼',
        cancelled_customer: '고객예약취소',
        cancelled_store: '상점예약취소'
    };
    return React.createElement(StyledScheduleStatusTag, { color: color, children: childrens[status] || '확정' });
};
var StyledScheduleStatusTag = styled(ColorTagWithWhiteFont)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", " !important;\n"], ["\n  color: ", " !important;\n"])), COLORS.black);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
